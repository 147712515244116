import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addresses,
  APP_STORAGE_ALL_PASSPORTDETAILS,
  getAnynetStaticProvider,
  getAppConfiguration,
  getStaticLogsProvider,
  isNativeChain,
  NetworkId,
  NETWORKS,
  NOTIFICATIONS,
  PRIVATE_MESSAGE_FRIEND_REQUESTS,
  ZERO_ADDRESS,
} from '../appconstants';
import {
  IAddressOnlyAsyncThunk,
  IBaseAsyncThunk,
  IFollowUserBaseAsyncThunk,
  IMessageMetaData,
  INotificationAsyncThunk,
} from './interfaces';
import {
  Loot8Token__factory,
  Loot8Collection__factory,
  CollectionManager__factory,
  Loot8Marketplace__factory,
} from '../typechain/factories';
import { BigNumber, ethers } from 'ethers';
import {
  GetFollowUserMessage,
  GetUnFollowUserMessage,
} from '../helpers/MsgHelper';
import { SendMetaTX } from './AppSlice';
import { RootState } from '../store';
import { uniqWith } from 'lodash';
import { getData, storeData } from '../helpers/AppStorage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getUserAvatar, loadUserBalance } from './AppUserSlice';
import { showToastMessage } from '../helpers/Gadgets';
import { LogCustomError } from '../helpers/AppLogger';
import { CollectionType } from '../enums/collection.enum';
import { getCollectibleDetails } from './OfferSlice';
import { getAllMintedCollectionForPatron } from './CatalogSlice';
import {
  IFriendMessageRequest,
  getPrivateMsgRequestById,
} from './PrivateMessageSlice';
import {
  getCurrentEpochUnixTimeStamp,
  getSyncedTime,
} from '../helpers/DateHelper';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { loadFriendsData } from './friendsSlice';
import { IMessageList } from '../interfaces/IMessages';
import { updatePassportDetails } from './PassportSlice';
import { IBatchRequest } from '../interfaces/IBatchRequest';
import { batchExecuteCalls } from '../helpers/APIHelper';
import { RequestFunctionType } from '../enums/batchRequest.enum';

export enum NotificationType {
  UserStatusChange,
  LoyaltyTokenMint,
  OfferMint,
  CollectibleMint,
  EventMint,
  PassportMint,
  PrivateMsgRequestAccepted,
  NewPrivateMessage,
  CouponMint,
  CollectibleSold,
  PassportSold,
  PrivateMsgRequestReceived,
}

export interface NotificationState {
  loading: boolean;
  showNotificationModal: boolean;
  notifications: INotification[];
  lastBlockNumber: number;
  lastTimestamp: number;
  walletAddress: string;
}

export interface INotification {
  id: string | number;
  notificationType: NotificationType;
  subject: string;
  body?: string;
  timeStamp: number;
  blockNumber?: number;
  shown?: boolean;
  uri?: string;
  lastBlockNumberOtherChain?: number;
  dataObject?: any;
}

interface ICollectionListWithChainID {
  collectibleAddress: string;
  chainID: string;
}

let existingCollectionData = {
  collectibles: [],
  events: [],
};

const processBatchResponse = async (
  batchResponse,
  collectionListWithChainID: ICollectionListWithChainID[],
  partialResponseID,
) => {
  try {
    let processedBatchResponse = batchResponse;
    const availableResponses = batchResponse?.filter(
      r => r.response.length > 0,
    );
    let mintedBlockRequests: IBatchRequest[] = [];
    let mintedBlockResponses = [];
    availableResponses?.forEach(br => {
      let response = br.response;
      //const collection = collectionListWithChainID.find(c => ("mintQueryFilter: " + c.collectibleAddress).toLowerCase() === br.id.toLowerCase());
      const collection = collectionListWithChainID.find(
        c =>
          (partialResponseID + c.collectibleAddress).toLowerCase() ===
          br.id.toLowerCase(),
      );
      response.forEach(r => {
        if (
          !r.getBlock &&
          r.blockHash &&
          collection?.collectibleAddress &&
          collection?.chainID
        ) {
          let mintedBlockRequest: IBatchRequest = {
            id: 'blockByHash: ' + r.blockHash,
            chainId: collection?.chainID?.toString(),
            contractAddress: collection?.collectibleAddress,
            functionType: RequestFunctionType.BLOCK_BY_HASH,
            blockByHashParams: [r.blockHash, false],
          };
          mintedBlockRequests.push(mintedBlockRequest);
        }
      });
    });
    try {
      mintedBlockResponses = await batchExecuteCalls(mintedBlockRequests);
    } catch (err) {
      LogToLoot8Console(
        'NotificationSlice processBatchResponse',
        err.name,
        err.message,
        err.stack,
      );
      LogCustomError(
        'NotificationSlice processBatchResponse',
        err.name,
        err.message,
        err.stack,
      );
    }

    if (mintedBlockResponses) {
      processedBatchResponse = await Promise.all(
        batchResponse?.map(async br => {
          let processedResponse = await Promise.all(
            br.response.map(async r => {
              const mintBlockResponse =
                mintedBlockResponses?.length > 0
                  ? mintedBlockResponses.find(
                      x =>
                        x.id.toLowerCase() ==
                        ('blockByHash: ' + r.blockHash).toLowerCase(),
                    )
                  : null;
              if (mintBlockResponse) {
                return {
                  ...r,
                  timestamp: getCurrentEpochUnixTimeStamp(
                    mintBlockResponse?.response?.timestamp,
                  ),
                };
              } else {
                return r;
              }
            }),
          );
          return { ...br, response: processedResponse };
        }),
      );
    }
    return processedBatchResponse;
  } catch (err) {
    LogToLoot8Console(
      'NotificationSlice processBatchResponse batchify-contract-call-blockByHash: ',
      err.name,
      err.message,
      err.stack,
    );
    LogCustomError(
      'NotificationSlice processBatchResponse batchify-contract-call-blockByHash: ',
      err.name,
      err.message,
      err.stack,
    );
  }
};

export const processFriendRequestNotifications = async (
  address,
  privateFriendRequestReceived,
  privateFriendRequestReceivedLoading,
  dispatch,
) => {
  const notificationConfig = (await getAppConfiguration())?.notification;

  let notifications: INotification[] = [];
  const storedNotifications = await getData(NOTIFICATIONS + address);
  if (notificationConfig?.allowance?.friendRequestReceived) {
    const requestsFromLoggedInUser = !privateFriendRequestReceivedLoading
      ? privateFriendRequestReceived?.filter(r => r?.user?.wallet !== address)
      : [];
    if (requestsFromLoggedInUser?.length > 0) {
      let requestsObj: IMessageList[] = [];
      for (let i = 0; i < requestsFromLoggedInUser.length; i++) {
        let req = requestsFromLoggedInUser[i];

        const hasSameFriendRequest = storedNotifications?.notifications
          ?.filter(
            x =>
              x.notificationType == NotificationType.PrivateMsgRequestReceived,
          )
          .find(p => {
            let splitKeys = p && p.id ? p.id.split('||') : [];
            if (splitKeys?.length > 0) {
              return splitKeys?.find(
                x => x == req?.user?.wallet + '-' + req.messageId,
              );
            }
          });

        if (!hasSameFriendRequest) {
          requestsObj.push(req);
        }
      }
      if (requestsObj?.length > 0) {
        let userProfileImage = null;
        let filterRequestObj = requestsObj?.filter(
          x => x.user?.name && x.user?.wallet != ZERO_ADDRESS,
        );
        let buildKeys = [];

        filterRequestObj?.map(x => {
          buildKeys.push(x?.user?.wallet + '-' + x.messageId);
        });

        filterRequestObj
          ?.filter(x => 1 == 1)
          .map(async x => {
            userProfileImage = await getUserAvatar(x?.user?.avatarURI ?? '');
            notifications.push({
              subject:
                filterRequestObj?.length == 1
                  ? x?.user?.name + ' wants to be your friend'
                  : `You have received ${filterRequestObj?.length} friend request`,
              body: 'Tap to review and respond',
              uri: userProfileImage ? userProfileImage : null,
              timeStamp: Date.now(),
              id: buildKeys?.join('||'),
              notificationType: NotificationType.PrivateMsgRequestReceived,
              dataObject: filterRequestObj?.length > 1 ? true : false,
            });
            dispatch(pushNotification(notifications));
          });
      }
    }
  }
};

// * Disabling this Feature
// * Fetching eth_getLogs in-efficiently
// !TODO: Replace with Indexer queries instead of reading direct blockchain logs
export const getUnsubsribedNotifications = createAsyncThunk(
  'app/getAllNotifications',
  async (
    { networkID, provider, address, wallet }: INotificationAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    return;

    // let notifications: INotification[] = [];
    // const storedNotifications = await getData(NOTIFICATIONS + address);
    // const store = getState() as RootState;
    // let lastBlockNumber = store.notification.lastBlockNumber;

    // const notificationConfig = (await getAppConfiguration())?.notification;

    // if (
    //   !notificationConfig.backgroundWithPassportLoad &&
    //   (!store.Passports.initialPassportLoaded ||
    //     store.Passports.loading ||
    //     store.Passports.loadingAllPassport)
    // ) {
    //   return;
    // }

    // const currentExecTime = await AsyncStorage.getItem(
    //   '@notificationLastExecutionTime',
    // );
    // if (currentExecTime) {
    //   if (
    //     getSyncedTime() - Number(currentExecTime) <=
    //     (notificationConfig?.frequency || 60000)
    //   ) {
    //     if (storedNotifications && storedNotifications.lastBlockNumber > 0) {
    //       lastBlockNumber = storedNotifications.lastBlockNumber + 1;
    //       notifications = storedNotifications.notifications;
    //       dispatch(pushNotification(notifications));
    //     }
    //     // return;
    //   }
    // }

    // if (storedNotifications && storedNotifications.lastBlockNumber > 0) {
    //   lastBlockNumber = storedNotifications.lastBlockNumber + 1;
    //   notifications = storedNotifications.notifications;
    // }

    // if (lastBlockNumber === 0) {
    //   lastBlockNumber = (await provider.getBlockNumber()) - 500;
    // }

    // const allKeys = await AsyncStorage.getAllKeys();
    // const allCollectibleKeys = allKeys.filter(p =>
    //   p.startsWith('@collectible_'),
    // );
    // let allCollectibles = [];
    // if (allCollectibleKeys && allCollectibleKeys.length > 0) {
    //   for (let col = 0; col < allCollectibleKeys.length; col++) {
    //     const collection = await getData(allCollectibleKeys[col]);
    //     if (collection) {
    //       allCollectibles.push(collection);
    //     }
    //   }
    // }
    // const logsProvider = getStaticLogsProvider(networkID);

    // // Private message friend request received.

    // const privateFriendRequestReceived = store.PrivateMessage.pendingRequest;
    // const privateFriendRequestReceivedLoading =
    //   store.PrivateMessage.requestLoading;

    // processFriendRequestNotifications(
    //   address,
    //   privateFriendRequestReceived,
    //   privateFriendRequestReceivedLoading,
    //   dispatch,
    // );

    // //Token Minted
    // if (notificationConfig?.allowance?.loot8erc20) {
    //   const daoERC20 = Loot8Token__factory.connect(
    //     addresses[networkID].DAOERC20,
    //     logsProvider,
    //   );
    //   try {
    //     console.log(
    //       'Getting Token Minted - QueryFilter - BlockNumber: ',
    //       lastBlockNumber,
    //     );
    //     let tokenMintedEventFilter = await daoERC20.queryFilter(
    //       daoERC20.filters.Transfer(ethers.constants.AddressZero, address),
    //       lastBlockNumber,
    //     );
    //     const tokenMintedEvents = tokenMintedEventFilter.sort(
    //       (a, b) => b.blockNumber - a.blockNumber,
    //     );
    //     if (tokenMintedEvents && tokenMintedEvents.length > 0) {
    //       for (let i = 0; i < tokenMintedEvents.length; i++) {
    //         const tokenMinted = tokenMintedEvents[i];
    //         const timestamp = getCurrentEpochUnixTimeStamp(
    //           (await tokenMinted.getBlock()).timestamp,
    //         );
    //         const tokenAmt = +tokenMinted.args[2] / 1e18;
    //         notifications.push({
    //           subject: 'Loyalty Points awarded!',
    //           body:
    //             tokenAmt.toFixed(2) +
    //             ' point' +
    //             (tokenAmt > 1 ? 's' : '') +
    //             ' added to your wallet',
    //           timeStamp: timestamp,
    //           blockNumber: tokenMinted.blockNumber,
    //           id: tokenMinted.transactionHash,
    //           notificationType: NotificationType.LoyaltyTokenMint,
    //           dataObject: tokenMinted,
    //         });
    //       }

    //       dispatch(pushNotification(notifications));
    //     }
    //   } catch (error) {
    //     console.error('Error in daoERC20.queryFilter', error);
    //   }
    // }

    // //Passport Minted
    // if (notificationConfig?.allowance?.passportMint) {
    //   const availablePassport = store.Passports;

    //   if (
    //     availablePassport &&
    //     availablePassport.AllPassportDetailsList &&
    //     availablePassport.AllPassportDetailsList.filter(x => x.isActive)
    //       .length > 0
    //   ) {
    //     let passportChainDetails = [];
    //     let buildPassportResquestObj: Array<IBatchRequest> = [];
    //     let collectibleAddress;

    //     let batchPassportResponse = null;
    //     for (
    //       let i = 0;
    //       i < availablePassport.AllPassportDetailsList.length;
    //       i++
    //     ) {
    //       const activePassport = availablePassport.AllPassportDetailsList[i];
    //       passportChainDetails.push({
    //         collectibleAddress: activePassport.address,
    //         chainID: activePassport.chainId,
    //       });

    //       const passportContract = Loot8Collection__factory.connect(
    //         activePassport.address,
    //         logsProvider,
    //       );
    //       let mintPassportRequest: IBatchRequest = {
    //         id: 'mintQueryFilter: ' + activePassport.address,
    //         chainId: activePassport.chainId?.toString(),
    //         contractAddress: activePassport.address,
    //         functionType: RequestFunctionType.QUERYFILTER,
    //         queryFilterParams: {
    //           fromBlock: '0x' + lastBlockNumber?.toString(16),
    //           topics: passportContract.filters.Transfer(
    //             ethers.constants.AddressZero,
    //             address,
    //           ).topics,
    //         },
    //       };
    //       buildPassportResquestObj.push(mintPassportRequest);
    //     }

    //     try {
    //       console.log(
    //         'Getting Passports Minted - Batched - QueryFilter - BlockNumber: ',
    //         lastBlockNumber,
    //       );

    //       batchPassportResponse = await batchExecuteCalls(
    //         buildPassportResquestObj,
    //       );
    //     } catch (err) {
    //       LogToLoot8Console(
    //         'NotificationSlice Passport Mint: batchify-contract-call-' +
    //           collectibleAddress,
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //       LogCustomError(
    //         'NotificationSlice Passport Mint: batchify-contract-call-' +
    //           collectibleAddress,
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //     }
    //     const processedPassportBatchRes = await processBatchResponse(
    //       batchPassportResponse,
    //       passportChainDetails,
    //       'mintQueryFilter: ',
    //     );

    //     for (
    //       let i = 0;
    //       i < availablePassport.AllPassportDetailsList.length;
    //       i++
    //     ) {
    //       const activePassport = availablePassport.AllPassportDetailsList[i];

    //       if (
    //         notifications &&
    //         notifications.findIndex(p => p.id == activePassport.address) ==
    //           -1 &&
    //         activePassport.collectibleIds?.length > 0
    //       ) {
    //         try {
    //           let passportsMintedFilter = [];
    //           const batchPassportMintedResponse =
    //             processedPassportBatchRes?.length > 0
    //               ? processedPassportBatchRes.find(
    //                   x =>
    //                     x.id.toLowerCase() ==
    //                     (
    //                       'mintQueryFilter: ' + activePassport.address
    //                     ).toLowerCase(),
    //                 )
    //               : null;
    //           if (batchPassportMintedResponse) {
    //             passportsMintedFilter = batchPassportMintedResponse?.response;
    //           } else {
    //             // If Batching Doesn't work
    //             const passportContract = Loot8Collection__factory.connect(
    //               activePassport.address,
    //               logsProvider,
    //             );

    //             console.log(
    //               'Getting Passport Minted - QueryFilter - BlockNumber: ',
    //               lastBlockNumber,
    //             );
    //             passportsMintedFilter = await passportContract.queryFilter(
    //               passportContract.filters.Transfer(
    //                 ethers.constants.AddressZero,
    //                 address,
    //               ),
    //               lastBlockNumber,
    //             );
    //           }
    //           const passportsMinted = passportsMintedFilter.sort(
    //             (a, b) => b.blockNumber - a.blockNumber,
    //           );
    //           for (let i = 0; i < passportsMinted.length; i++) {
    //             const passportMinted = passportsMinted[i];

    //             const timestamp = passportMinted.timestamp
    //               ? passportMinted.timestamp
    //               : passportMinted.getBlock
    //                 ? getCurrentEpochUnixTimeStamp(
    //                     (await passportMinted.getBlock()).timestamp,
    //                   )
    //                 : null;
    //             notifications.push({
    //               subject: 'Yay! You got a new passport',
    //               body:
    //                 activePassport.name +
    //                 ' passport has been added to your portfolio',
    //               timeStamp: timestamp,
    //               blockNumber: passportMinted.blockNumber,
    //               id: activePassport.address,
    //               notificationType: NotificationType.PassportMint,
    //               dataObject: activePassport,
    //             });
    //           }
    //         } catch (error) {
    //           LogToLoot8Console('Error in passportContract.queryFilter', error);
    //         }
    //       }
    //     }

    //     dispatch(pushNotification(notifications));
    //   }
    // }

    // //Event Minted
    // if (notificationConfig?.allowance?.eventMint) {
    //   const allEventKeys = allCollectibles?.filter(
    //     p => p?.collectionType && p.collectionType == CollectionType.EVENT,
    //   );

    //   if (
    //     allEventKeys &&
    //     allEventKeys.length > 0 &&
    //     existingCollectionData.events.length !==
    //       allEventKeys.filter(p => p.active).length
    //   ) {
    //     existingCollectionData.events = allEventKeys.filter(p => p.active);
    //     let eventChainDetails = [];
    //     let buildEventResquestObj: Array<IBatchRequest> = [];

    //     let batchEventsResponse = null;
    //     for (let i = 0; i < allEventKeys.length; i++) {
    //       const event = allEventKeys[i];
    //       eventChainDetails.push({
    //         collectibleAddress: event.address,
    //         chainID: event.chainId,
    //       });

    //       const eventContract = Loot8Collection__factory.connect(
    //         event.address,
    //         logsProvider,
    //       );
    //       let mintPassportRequest: IBatchRequest = {
    //         id: 'mintQueryFilter: ' + event.address,
    //         chainId: event.chainId?.toString(),
    //         contractAddress: event.address,
    //         functionType: RequestFunctionType.QUERYFILTER,
    //         queryFilterParams: {
    //           fromBlock: '0x' + lastBlockNumber?.toString(16),
    //           topics: eventContract.filters.Transfer(
    //             ethers.constants.AddressZero,
    //             address,
    //           ).topics,
    //         },
    //       };
    //       buildEventResquestObj.push(mintPassportRequest);
    //     }

    //     try {
    //       console.log(
    //         'Getting Event Mints - Batched - QueryFilter - BlockNumber: ',
    //         lastBlockNumber,
    //       );

    //       batchEventsResponse = await batchExecuteCalls(buildEventResquestObj);
    //     } catch (err) {
    //       LogToLoot8Console(
    //         'NotificationSlice Event Mint: batchify-contract-call-',
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //       LogCustomError(
    //         'NotificationSlice Event Mint: batchify-contract-call-',
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //     }
    //     const processedEventsBatchRes = await processBatchResponse(
    //       batchEventsResponse,
    //       eventChainDetails,
    //       'mintQueryFilter: ',
    //     );

    //     for (let i = 0; i < allEventKeys.length; i++) {
    //       const event = allEventKeys[i];

    //       if (event && event.isActive && event.collectibleIds?.length > 0) {
    //         try {
    //           let eventsMintedFilter = [];
    //           const batchPassportMintedResponse =
    //             processedEventsBatchRes?.length > 0
    //               ? processedEventsBatchRes.find(
    //                   x =>
    //                     x.id.toLowerCase() ==
    //                     ('mintQueryFilter: ' + event.address).toLowerCase(),
    //                 )
    //               : null;
    //           if (batchPassportMintedResponse) {
    //             eventsMintedFilter = batchPassportMintedResponse?.response;
    //           } else {
    //             const eventContract = Loot8Collection__factory.connect(
    //               event.address,
    //               logsProvider,
    //             );

    //             console.log(
    //               'Getting Event Minted - QueryFilter - BlockNumber: ',
    //               lastBlockNumber,
    //             );
    //             eventsMintedFilter = await eventContract.queryFilter(
    //               eventContract.filters.Transfer(
    //                 ethers.constants.AddressZero,
    //                 address,
    //               ),
    //               lastBlockNumber,
    //             );
    //           }
    //           const eventsMinted = eventsMintedFilter.sort(
    //             (a, b) => b.blockNumber - a.blockNumber,
    //           );

    //           for (let i = 0; i < eventsMinted.length; i++) {
    //             const eventMinted = eventsMinted[i];

    //             const timestamp = eventMinted.timestamp
    //               ? eventMinted.timestamp
    //               : eventMinted.getBlock
    //                 ? getCurrentEpochUnixTimeStamp(
    //                     (await eventMinted.getBlock()).timestamp,
    //                   )
    //                 : null;
    //             notifications.push({
    //               subject: 'Save the date and experience!',
    //               body: event.name + ' has been added to your portfolio',
    //               timeStamp: timestamp,
    //               blockNumber: eventMinted.blockNumber,
    //               id: eventMinted.transactionHash,
    //               notificationType: NotificationType.EventMint,
    //               dataObject: event,
    //             });
    //           }
    //         } catch (error) {
    //           console.error('Error in passportContract.queryFilter', error);
    //         }
    //       }
    //     }

    //     dispatch(pushNotification(notifications));
    //   }
    // }

    // //Collectibles - Minted & P2P Transfer
    // //Fetch list of all collectibles in the system, to handle the case wherein logged in user does not have transferred collectible already.
    // if (notificationConfig?.allowance?.collectibleMint) {
    //   const allCollectiableKeys = allCollectibles?.filter(
    //     p => p?.collectionType && p.collectionType == CollectionType.COLLECTION,
    //   );
    //   const AllCollectiblesWithChainId = await getAllMintedCollectionForPatron(
    //     {
    //       networkID,
    //       provider,
    //       address,
    //       collectionType: CollectionType.COLLECTION,
    //     },
    //     false,
    //   );

    //   // batchify api requests
    //   let batchResponse = null;
    //   if (
    //     AllCollectiblesWithChainId &&
    //     AllCollectiblesWithChainId.length > 0 &&
    //     existingCollectionData.collectibles.length !==
    //       AllCollectiblesWithChainId.length
    //   ) {
    //     existingCollectionData.collectibles = AllCollectiblesWithChainId;
    //     //get various providers & lastblocknumbers as per available networks
    //     let allOtherNetworks = Object.keys(NetworkId)
    //       .filter(key => !isNaN(Number(NetworkId[key])))
    //       .filter(
    //         n =>
    //           !isNativeChain(NetworkId[n])
    //       );

    //     let allOtherProviders = await Promise.all(
    //       allOtherNetworks
    //         .filter(q =>
    //           AllCollectiblesWithChainId.find(p => p.chainId == NetworkId[q]),
    //         )
    //         .map(async n => {
    //           let providerToPass = getStaticLogsProvider(NetworkId[n]);
    //           let lastBlockNumberToPass =
    //             (await providerToPass.getBlockNumber()) - 500;
    //           return {
    //             chainId: NetworkId[n],
    //             provider: providerToPass,
    //             lastBlockNumber: lastBlockNumberToPass,
    //           };
    //         }),
    //     );
    //     const state = getState() as RootState;

    //     let collectibleChainDetails = [];
    //     let buildResquestObj: Array<IBatchRequest> = [];
    //     let collectibleAddress;
    //     for (let i = 0; i < AllCollectiblesWithChainId.length; i++) {
    //       collectibleAddress = AllCollectiblesWithChainId[i].source;
    //       const collectibleChainId = Number(
    //         AllCollectiblesWithChainId[i].chainId,
    //       );

    //       let providerToPass = logsProvider;
    //       let lastBlockNumberToPass;
    //       let isArbitrum = true;

    //       if (!isNativeChain(collectibleChainId)) {
    //         let otherChainDetails = allOtherProviders.find(
    //           p => p.chainId === collectibleChainId,
    //         );
    //         if (otherChainDetails) {
    //           isArbitrum = false;
    //           providerToPass = otherChainDetails.provider;
    //           lastBlockNumberToPass = otherChainDetails.lastBlockNumber;
    //         }
    //       }

    //       const blockNumberToPass = !isArbitrum
    //         ? lastBlockNumberToPass
    //         : lastBlockNumber;
    //       collectibleChainDetails.push({
    //         collectibleAddress: collectibleAddress,
    //         chainID: collectibleChainId,
    //         providerToPass: providerToPass,
    //         isArbitrum: isArbitrum,
    //         lastBlockNumberToPass: blockNumberToPass,
    //       });

    //       const collectibleContract = Loot8Collection__factory.connect(
    //         collectibleAddress,
    //         providerToPass,
    //       );

    //       //const mintTransferParams : IQueryFilterParams = {topics : collectibleContract.filters.Transfer(ethers.constants.AddressZero, address).topics, fromBlock: blockNumberToPass}
    //       let mintCollectibleRequest: IBatchRequest = {
    //         id: 'mintQueryFilter: ' + collectibleAddress,
    //         chainId: collectibleChainId.toString(),
    //         contractAddress: collectibleAddress,
    //         functionType: RequestFunctionType.QUERYFILTER,
    //         queryFilterParams: {
    //           fromBlock: '0x' + blockNumberToPass?.toString(16),
    //           topics: collectibleContract.filters.Transfer(
    //             ethers.constants.AddressZero,
    //             address,
    //           ).topics,
    //         },
    //       };
    //       buildResquestObj.push(mintCollectibleRequest);

    //       let p2pTransferCollectibleRequest: IBatchRequest = {
    //         id: 'transferQueryFilter: ' + collectibleAddress,
    //         chainId: collectibleChainId.toString(),
    //         contractAddress: collectibleAddress,
    //         functionType: RequestFunctionType.QUERYFILTER,
    //         queryFilterParams: {
    //           fromBlock: '0x' + blockNumberToPass?.toString(16),
    //           topics: collectibleContract.filters.Transfer(null, address)
    //             .topics,
    //         },
    //       };
    //       buildResquestObj.push(p2pTransferCollectibleRequest);
    //     }

    //     try {
    //       console.log(
    //         'Getting Mints Query - Batched - QueryFilter - BlockNumber: ',
    //         lastBlockNumber,
    //       );
    //       console.log(
    //         'Getting Transfers Query - Batched - QueryFilter - BlockNumber: ',
    //         lastBlockNumber,
    //       );

    //       batchResponse = await batchExecuteCalls(buildResquestObj);
    //     } catch (err) {
    //       LogToLoot8Console(
    //         'NotificationSlice Collections Mint/Transfer: batchify-contract-call-' +
    //           collectibleAddress,
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //       LogCustomError(
    //         'NotificationSlice Collections Mint/Transfer: batchify-contract-call-' +
    //           collectibleAddress,
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //     }
    //     const processedBatchResponse = await processBatchResponse(
    //       batchResponse,
    //       collectibleChainDetails,
    //       'mintQueryFilter: ',
    //     );

    //     for (let i = 0; i < AllCollectiblesWithChainId.length; i++) {
    //       try {
    //         const collectibleAddress = AllCollectiblesWithChainId[i].source;
    //         const collectibleChainId = Number(
    //           AllCollectiblesWithChainId[i].chainId,
    //         );

    //         let isArbitrum;
    //         let providerToPass;
    //         let lastBlockNumberToPass;
    //         if (collectibleChainDetails && collectibleChainDetails.length > 0) {
    //           const details = collectibleChainDetails.find(
    //             c => c.collectibleAddress === collectibleAddress,
    //           );
    //           if (details) {
    //             isArbitrum = details.isArbitrum;
    //             providerToPass = details.providerToPass;
    //             lastBlockNumberToPass = details.lastBlockNumberToPass;
    //           }
    //         }

    //         const collectibleContract = Loot8Collection__factory.connect(
    //           collectibleAddress,
    //           providerToPass,
    //         );

    //         //Collectible Mint
    //         let collectiblesMintedFilter = [];
    //         const batchCollectibleMintedResponse =
    //           processedBatchResponse?.length > 0
    //             ? processedBatchResponse.find(
    //                 x =>
    //                   x.id.toLowerCase() ==
    //                   ('mintQueryFilter: ' + collectibleAddress).toLowerCase(),
    //               )
    //             : null;
    //         if (batchCollectibleMintedResponse) {
    //           collectiblesMintedFilter =
    //             batchCollectibleMintedResponse?.response;
    //         } else if (
    //           providerToPass &&
    //           (isArbitrum === true || lastBlockNumberToPass)
    //         ) {
    //           console.log(
    //             'Getting Collectible Minted - QueryFilter - BlockNumber: ',
    //             lastBlockNumber,
    //           );
    //           collectiblesMintedFilter = await collectibleContract.queryFilter(
    //             collectibleContract.filters.Transfer(
    //               ethers.constants.AddressZero,
    //               address,
    //             ),
    //             !isArbitrum ? lastBlockNumberToPass : lastBlockNumber,
    //           );
    //         }
    //         const collectiblesMinted = collectiblesMintedFilter.sort(
    //           (a, b) => b?.blockNumber - a?.blockNumber,
    //         );

    //         //P2P Transfer
    //         let collectiblesP2PTransferFilter = [];
    //         const batchCollectibleTransferResponse =
    //           batchResponse?.length > 0
    //             ? batchResponse.find(
    //                 x =>
    //                   x.id.toLowerCase() ==
    //                   (
    //                     'transferQueryFilter: ' + collectibleAddress
    //                   ).toLowerCase(),
    //               )
    //             : null;
    //         if (batchCollectibleTransferResponse) {
    //           collectiblesP2PTransferFilter =
    //             batchCollectibleTransferResponse?.response;
    //         } else if (
    //           providerToPass &&
    //           (isArbitrum === true || lastBlockNumberToPass)
    //         ) {
    //           console.log(
    //             'Getting Collectible Transfer - QueryFilter - BlockNumber: ',
    //             lastBlockNumber,
    //           );
    //           collectiblesP2PTransferFilter =
    //             await collectibleContract.queryFilter(
    //               collectibleContract.filters.Transfer(null, address),
    //               !isArbitrum ? lastBlockNumberToPass : lastBlockNumber,
    //             );
    //         }
    //         let sortedCollectiblesP2PTransferFilter =
    //           collectiblesP2PTransferFilter.sort(
    //             (a, b) => b?.blockNumber - a?.blockNumber,
    //           );

    //         let latestCollectiblesP2PTransfer;
    //         if (
    //           sortedCollectiblesP2PTransferFilter &&
    //           sortedCollectiblesP2PTransferFilter.length > 0
    //         ) {
    //           latestCollectiblesP2PTransfer =
    //             sortedCollectiblesP2PTransferFilter[0];
    //           if (!latestCollectiblesP2PTransfer.args) {
    //             try {
    //               const parsedData = collectibleContract.interface.parseLog(
    //                 latestCollectiblesP2PTransfer,
    //               );
    //               if (parsedData && parsedData.args) {
    //                 latestCollectiblesP2PTransfer = {
    //                   ...latestCollectiblesP2PTransfer,
    //                   args: parsedData.args,
    //                 };
    //               }
    //             } catch (e) {
    //               LogToLoot8Console(
    //                 'Collectibles P2P Transfer Notification: could not parse event log',
    //               );
    //             }
    //           }
    //           if (!latestCollectiblesP2PTransfer.getBlock) {
    //             let requestObject: IBatchRequest[] = [];
    //             let batchResponses;
    //             let tansferCollectibleBlockTimeRequest: IBatchRequest = {
    //               id: 'blockByHash: ' + latestCollectiblesP2PTransfer.blockHash,
    //               chainId: collectibleChainId.toString(),
    //               contractAddress: collectibleAddress,
    //               functionType: RequestFunctionType.BLOCK_BY_HASH,
    //               blockByHashParams: [
    //                 latestCollectiblesP2PTransfer.blockHash,
    //                 false,
    //               ],
    //             };
    //             requestObject.push(tansferCollectibleBlockTimeRequest);
    //             try {
    //               batchResponses = await batchExecuteCalls(requestObject);
    //             } catch (err) {
    //               LogToLoot8Console(
    //                 'NotificationSlice Collections Transfer: getBlockByHash' +
    //                   collectibleAddress,
    //                 err.name,
    //                 err.message,
    //                 err.stack,
    //               );
    //               LogCustomError(
    //                 'NotificationSlice Collections Transfer: getBlockByHash' +
    //                   collectibleAddress,
    //                 err.name,
    //                 err.message,
    //                 err.stack,
    //               );
    //             }
    //             const response =
    //               batchResponses?.length > 0
    //                 ? batchResponses.find(
    //                     x =>
    //                       x.id.toLowerCase() ==
    //                       (
    //                         'blockByHash: ' +
    //                         latestCollectiblesP2PTransfer.blockHash
    //                       ).toLowerCase(),
    //                   )
    //                 : null;
    //             latestCollectiblesP2PTransfer = {
    //               ...latestCollectiblesP2PTransfer,
    //               timestamp: getCurrentEpochUnixTimeStamp(
    //                 response?.response?.timestamp,
    //               ),
    //             };
    //           } else {
    //             const blockTimestamp = getCurrentEpochUnixTimeStamp(
    //               (await latestCollectiblesP2PTransfer.getBlock()).timestamp,
    //             );
    //             latestCollectiblesP2PTransfer = {
    //               ...latestCollectiblesP2PTransfer,
    //               timestamp: blockTimestamp,
    //             };
    //           }
    //         }

    //         let collectible;
    //         if (
    //           (collectiblesMinted && collectiblesMinted.length > 0) ||
    //           latestCollectiblesP2PTransfer
    //         ) {
    //           collectible = allCollectiableKeys?.find(
    //             c => c.address === collectibleAddress,
    //           );
    //           if (!collectible) {
    //             //LogToLoot8Console('fetch collectible from contract');
    //             const entityData = state.Entity.EntityData;
    //             collectible = await getCollectibleDetails(
    //               {
    //                 networkID: collectibleChainId,
    //                 provider: providerToPass,
    //                 collectibleAddress: collectibleAddress,
    //                 address,
    //                 wallet: null,
    //               },
    //               { entityData },
    //               {},
    //             );
    //             //LogToLoot8Console(collectible);
    //           }
    //         }

    //         if (collectible) {
    //           if (collectible.isActive) {
    //             for (let i = 0; i < collectiblesMinted.length; i++) {
    //               const collectibleMinted = collectiblesMinted[i];

    //               const timestamp = collectibleMinted.timestamp
    //                 ? collectibleMinted.timestamp
    //                 : collectibleMinted.getBlock
    //                   ? getCurrentEpochUnixTimeStamp(
    //                       (await collectibleMinted.getBlock()).timestamp,
    //                     )
    //                   : null;
    //               notifications.push({
    //                 subject: 'Congrats! You just received a new collectible!',
    //                 body:
    //                   collectible.name + ' has been added to your portfolio',
    //                 //timeStamp: collectibleMinted.timestamp,
    //                 timeStamp: timestamp,
    //                 blockNumber: collectibleMinted.blockNumber,
    //                 id: collectibleMinted.transactionHash,
    //                 notificationType: NotificationType.CollectibleMint,
    //                 dataObject: collectible,
    //               });
    //             }
    //           }

    //           if (latestCollectiblesP2PTransfer) {
    //             let fromUserName = 'Loot8 User';
    //             if (
    //               latestCollectiblesP2PTransfer.args &&
    //               latestCollectiblesP2PTransfer.args.from
    //             ) {
    //               let fromUser: any = state.AppUser.AllUsersData.find(
    //                 (u: any) =>
    //                   u.wallet?.toLowerCase() ===
    //                   latestCollectiblesP2PTransfer.args.from?.toLowerCase(),
    //               );
    //               if (fromUser) {
    //                 fromUserName = fromUser.name;
    //               }
    //             }

    //             //const timestamp = latestCollectiblesP2PTransfer.timestamp ? latestCollectiblesP2PTransfer.timestamp : getCurrentEpochUnixTimeStamp((await latestCollectiblesP2PTransfer.getBlock()).timestamp);
    //             notifications.push({
    //               subject: 'Congrats! You just received a new collectible!',
    //               body:
    //                 'Yay! ' +
    //                 fromUserName.trim() +
    //                 ' has transferred the collectible ' +
    //                 collectible.name.trim() +
    //                 ' to you.',
    //               timeStamp: latestCollectiblesP2PTransfer.timestamp,
    //               blockNumber: isArbitrum
    //                 ? latestCollectiblesP2PTransfer.blockNumber
    //                 : lastBlockNumber,
    //               id: latestCollectiblesP2PTransfer.transactionHash,
    //               notificationType: NotificationType.CollectibleMint,
    //               lastBlockNumberOtherChain: !isArbitrum
    //                 ? lastBlockNumberToPass
    //                 : lastBlockNumber,
    //               dataObject: collectible,
    //             });
    //           }
    //         }
    //       } catch (error) {
    //         console.error(
    //           'Error in collectibleContract.queryFilter P2P transfer',
    //           error,
    //         );
    //       }
    //     }

    //     dispatch(pushNotification(notifications));
    //   }
    // }

    // //Other collectibles
    // const allOffers = store.Offers;

    // if (
    //   allOffers &&
    //   allOffers.ThirdPartyCollectiables &&
    //   allOffers.ThirdPartyCollectiables.length > 0
    // ) {
    //   for (let j = 0; j < allOffers.ThirdPartyCollectiables.length; j++) {
    //     const thirdPartyCollectible = allOffers.ThirdPartyCollectiables[j];
    //     if (thirdPartyCollectible && thirdPartyCollectible.isActive) {
    //       const hasSameCollectibles = notifications.find(
    //         p =>
    //           p.id ==
    //           thirdPartyCollectible.name +
    //             thirdPartyCollectible.address +
    //             thirdPartyCollectible.tokenId,
    //       );
    //       if (!hasSameCollectibles) {
    //         notifications.push({
    //           subject: 'Congrats! You just received a new collectible!',
    //           body:
    //             thirdPartyCollectible.name +
    //             ' has been added to your portfolio',
    //           timeStamp: Number.parseInt(getSyncedTime().toString()),
    //           id:
    //             thirdPartyCollectible.name +
    //             thirdPartyCollectible.address +
    //             thirdPartyCollectible.tokenId,
    //           notificationType: NotificationType.CollectibleMint,
    //           dataObject: thirdPartyCollectible,
    //         });
    //       }
    //     }
    //   }

    //   dispatch(pushNotification(notifications));
    // }

    // //Private Message Friend Request Accepted
    // const privateMsgFriendRequests: IFriendMessageRequest[] = await getData(
    //   PRIVATE_MESSAGE_FRIEND_REQUESTS,
    // );
    // if (privateMsgFriendRequests && privateMsgFriendRequests.length > 0) {
    //   const requestsFromLoggedInUser = privateMsgFriendRequests.filter(
    //     r => r.sender === address,
    //   );
    //   for (let i = 0; i < requestsFromLoggedInUser.length; i++) {
    //     let req = requestsFromLoggedInUser[i];
    //     const lastRequest = await dispatch(
    //       getPrivateMsgRequestById({
    //         networkID: networkID,
    //         address: req.receiver,
    //         wallet: wallet,
    //         messageById: req.requestMsgId,
    //       }),
    //     );
    //     if (
    //       !lastRequest ||
    //       !lastRequest.payload ||
    //       !lastRequest.payload.messages ||
    //       lastRequest.payload.messages.length === 0
    //     ) {
    //       //Sent request does not exist, hence accepted by receiver.
    //       // load friends
    //       await dispatch(
    //         loadFriendsData({
    //           networkID: networkID,
    //           provider: provider,
    //           wallet: wallet,
    //           address: address,
    //         }),
    //       );

    //       const currentState = getState() as RootState;
    //       const friendData = currentState.friends.currentFriends.filter(
    //         f => f.wallet?.toLowerCase() === req.receiver?.toLowerCase(),
    //       );
    //       const reveiverName =
    //         friendData && friendData.length > 0
    //           ? friendData[0].name
    //           : 'Loot8 User';
    //       const hasSameFriendRequest = storedNotifications?.notifications?.find(
    //         p =>
    //           p.id == req.sender + '-' + req.receiver + '-' + req.requestMsgId,
    //       );

    //       if (!hasSameFriendRequest) {
    //         notifications.push({
    //           subject: 'Friend Request Accepted!',
    //           body:
    //             reveiverName +
    //             ' has accepted your friend request. You can send message(s) to ' +
    //             reveiverName +
    //             ' now!',
    //           timeStamp: Date.now(),
    //           id: req.sender + '-' + req.receiver + '-' + req.requestMsgId,
    //           notificationType: NotificationType.PrivateMsgRequestAccepted,
    //           dataObject:
    //             friendData && friendData.length > 0 ? friendData[0] : null,
    //         });
    //       }
    //     }
    //   }

    //   dispatch(pushNotification(notifications));
    // }

    // // Collectible sold
    // if (notificationConfig?.allowance?.collectibleSold) {
    //   const allAvailableCollectible = store.DigitalCollectible;
    //   const availableCollectible =
    //     allAvailableCollectible?.AllDigitalCollectibleList?.filter(
    //       p => p.marketPlaceConfig?.allowMarketPlaceOps,
    //     );
    //   if (availableCollectible?.length > 0) {
    //     //get various providers & lastblocknumbers as per available networks
    //     let allOtherNetworks = Object.keys(NetworkId)
    //       .filter(key => !isNaN(Number(NetworkId[key])))
    //       .filter(
    //         n =>
    //           !isNativeChain(NetworkId[n])
    //       );

    //     let allOtherProviders = await Promise.all(
    //       allOtherNetworks
    //         .filter(q =>
    //           availableCollectible?.find(p => p.chainId == NetworkId[q]),
    //         )
    //         .map(async n => {
    //           let providerToPass = getStaticLogsProvider(NetworkId[n]);
    //           let lastBlockNumberToPass =
    //             (await providerToPass.getBlockNumber()) - 500;
    //           return {
    //             chainId: NetworkId[n],
    //             provider: providerToPass,
    //             lastBlockNumber: lastBlockNumberToPass,
    //           };
    //         }),
    //     );

    //     const state = getState() as RootState;

    //     let collectibleSoldChainDetails = [];
    //     let buildCollectibleTransferReqObj: Array<IBatchRequest> = [];
    //     let buildCollectibleSoldReqObj: Array<IBatchRequest> = [];
    //     let batchCollectibleTransferResponse = null;
    //     let batchCollectibleSoldResponse = null;
    //     //let collectibleAddress;
    //     for (let i = 0; i < availableCollectible?.length; i++) {
    //       const collectibleAddress = availableCollectible[i].address;
    //       const collectibleChainId = Number(availableCollectible[i].chainId);

    //       let providerToPass = logsProvider;
    //       let lastBlockNumberToPass;
    //       let isArbitrum = true;

    //       if (!isNativeChain(collectibleChainId)) {
    //         let otherChainDetails = allOtherProviders.find(
    //           p => p.chainId === collectibleChainId,
    //         );
    //         if (otherChainDetails) {
    //           isArbitrum = false;
    //           providerToPass = otherChainDetails.provider;
    //           lastBlockNumberToPass = otherChainDetails.lastBlockNumber;
    //         }
    //       }

    //       const blockNumberToPass = !isArbitrum
    //         ? lastBlockNumberToPass
    //         : lastBlockNumber;
    //       collectibleSoldChainDetails.push({
    //         collectibleAddress: collectibleAddress,
    //         chainID: collectibleChainId,
    //         providerToPass: providerToPass,
    //         isArbitrum: isArbitrum,
    //         lastBlockNumberToPass: blockNumberToPass,
    //       });

    //       const loot8Contract = Loot8Collection__factory.connect(
    //         collectibleAddress,
    //         providerToPass,
    //       );

    //       //const mintTransferParams : IQueryFilterParams = {topics : collectibleContract.filters.Transfer(ethers.constants.AddressZero, address).topics, fromBlock: blockNumberToPass}
    //       let passportTransferReqObj: IBatchRequest = {
    //         id: 'transferQueryFilter: ' + collectibleAddress,
    //         chainId: collectibleChainId.toString(),
    //         contractAddress: collectibleAddress,
    //         functionType: RequestFunctionType.QUERYFILTER,
    //         queryFilterParams: {
    //           fromBlock: '0x' + blockNumberToPass?.toString(16),
    //           topics: loot8Contract.filters.Transfer(address).topics,
    //         },
    //       };
    //       buildCollectibleTransferReqObj.push(passportTransferReqObj);

    //       const marketplaceContract = Loot8Marketplace__factory.connect(
    //         addresses[collectibleChainId].MarketPlace,
    //         providerToPass,
    //       );
    //       let passportSoldRequest: IBatchRequest = {
    //         id: 'itemSoldQueryFilter: ' + collectibleAddress,
    //         chainId: collectibleChainId.toString(),
    //         contractAddress: addresses[collectibleChainId].MarketPlace,
    //         functionType: RequestFunctionType.QUERYFILTER,
    //         queryFilterParams: {
    //           fromBlock: '0x' + blockNumberToPass?.toString(16),
    //           topics: marketplaceContract.filters.ItemSold().topics,
    //         },
    //       };
    //       buildCollectibleSoldReqObj.push(passportSoldRequest);
    //     }

    //     try {
    //       console.log(
    //         'Getting Collectible Transfers - Batched - QueryFilter - BlockNumber: ',
    //         lastBlockNumber,
    //       );

    //       batchCollectibleTransferResponse = await batchExecuteCalls(
    //         buildCollectibleTransferReqObj,
    //       );
    //     } catch (err) {
    //       LogToLoot8Console(
    //         'NotificationSlice Collectible Transfer: batchify-contract-call-',
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //       LogCustomError(
    //         'NotificationSlice Collectible Transfer: batchify-contract-call-',
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //     }

    //     for (let i = 0; i < availableCollectible?.length; i++) {
    //       const collectibleAddress = availableCollectible[i].address;
    //       const collectibleChainId = Number(availableCollectible[i].chainId);

    //       let providerToPass = logsProvider;
    //       let lastBlockNumberToPass;
    //       let isArbitrum = true;

    //       if (!isNativeChain(collectibleChainId)) {
    //         let otherChainDetails = allOtherProviders.find(
    //           p => p.chainId === collectibleChainId,
    //         );
    //         if (otherChainDetails) {
    //           isArbitrum = false;
    //           providerToPass = otherChainDetails.provider;
    //           lastBlockNumberToPass = otherChainDetails.lastBlockNumber;
    //         }
    //       }
    //       const collectibleContract = Loot8Collection__factory.connect(
    //         collectibleAddress,
    //         providerToPass,
    //       );
    //       try {
    //         let collectiblesTransferedFilter = [];
    //         const collectibleTransferResponse =
    //           batchCollectibleTransferResponse?.length > 0
    //             ? batchCollectibleTransferResponse.find(
    //                 x =>
    //                   x.id.toLowerCase() ==
    //                   (
    //                     'transferQueryFilter: ' + collectibleAddress
    //                   ).toLowerCase(),
    //               )
    //             : null;
    //         if (collectibleTransferResponse) {
    //           collectiblesTransferedFilter =
    //             collectibleTransferResponse?.response;
    //         } else if (
    //           providerToPass &&
    //           (isArbitrum === true || lastBlockNumberToPass)
    //         ) {
    //           console.log(
    //             'Getting Collectible Transfers - QueryFilter - BlockNumber: ',
    //             lastBlockNumber,
    //           );
    //           collectiblesTransferedFilter =
    //             await collectibleContract.queryFilter(
    //               collectibleContract.filters.Transfer(address),
    //               !isArbitrum ? lastBlockNumberToPass : lastBlockNumber,
    //             );
    //         }

    //         if (collectiblesTransferedFilter.length > 0) {
    //           collectiblesTransferedFilter = await Promise.all(
    //             collectiblesTransferedFilter.map(pt => {
    //               if (!pt.args) {
    //                 try {
    //                   const collectibleTransferLogs =
    //                     collectibleContract.interface.parseLog(pt);
    //                   if (
    //                     collectibleTransferLogs &&
    //                     collectibleTransferLogs.args
    //                   ) {
    //                     return { ...pt, args: collectibleTransferLogs.args };
    //                   }
    //                 } catch (e) {
    //                   LogToLoot8Console(
    //                     'Passport Sold Notification: could not parse event log',
    //                   );
    //                 }
    //               } else {
    //                 return pt;
    //               }
    //             }),
    //           );
    //           const collectiblesTransfer = collectiblesTransferedFilter.sort(
    //             (a, b) => b.blockNumber - a.blockNumber,
    //           );

    //           const entityData = state.Entity.EntityData;

    //           try {
    //             console.log(
    //               'Getting Collectible Sold Marketplace Data - Batched - QueryFilter - BlockNumber: ',
    //               lastBlockNumber,
    //             );
    //             batchCollectibleSoldResponse = await batchExecuteCalls(
    //               buildCollectibleSoldReqObj,
    //             );
    //           } catch (err) {
    //             LogToLoot8Console(
    //               'NotificationSlice Collectible Sold: batchify-contract-call-',
    //               err.name,
    //               err.message,
    //               err.stack,
    //             );
    //             LogCustomError(
    //               'NotificationSlice Collectible Sold: batchify-contract-call-',
    //               err.name,
    //               err.message,
    //               err.stack,
    //             );
    //           }
    //           const processedCollectibleSoldRes = await processBatchResponse(
    //             batchCollectibleSoldResponse,
    //             collectibleSoldChainDetails,
    //             'itemSoldQueryFilter: ',
    //           );

    //           // get collectible sold list
    //           let collectiblesSoldFilter = [];
    //           const marketplaceContract = Loot8Marketplace__factory.connect(
    //             addresses[collectibleChainId].MarketPlace,
    //             providerToPass,
    //           );
    //           const collectibleSoldResponse =
    //             processedCollectibleSoldRes?.length > 0
    //               ? processedCollectibleSoldRes.find(
    //                   x =>
    //                     x.id.toLowerCase() ==
    //                     (
    //                       'itemSoldQueryFilter: ' + collectibleAddress
    //                     ).toLowerCase(),
    //                 )
    //               : null;
    //           if (collectibleSoldResponse) {
    //             collectiblesSoldFilter = collectibleSoldResponse?.response;
    //             if (collectiblesSoldFilter.length > 0) {
    //               collectiblesSoldFilter = await Promise.all(
    //                 collectiblesSoldFilter.map(ps => {
    //                   if (!ps.args) {
    //                     try {
    //                       const collectibleSoldLogs =
    //                         marketplaceContract.interface.parseLog(ps);
    //                       if (collectibleSoldLogs && collectibleSoldLogs.args) {
    //                         return { ...ps, args: collectibleSoldLogs.args };
    //                       }
    //                     } catch (e) {
    //                       LogToLoot8Console(
    //                         'Collectible Sold Notification: could not parse event log',
    //                       );
    //                     }
    //                   } else {
    //                     return ps;
    //                   }
    //                 }),
    //               );
    //             }
    //           } else if (
    //             providerToPass &&
    //             (isArbitrum === true || lastBlockNumberToPass)
    //           ) {
    //             console.log(
    //               'Getting Collectible Sold - QueryFilter - BlockNumber: ',
    //               lastBlockNumber,
    //             );
    //             collectiblesSoldFilter = await marketplaceContract.queryFilter(
    //               marketplaceContract.filters.ItemSold(),
    //               !isArbitrum ? lastBlockNumberToPass : lastBlockNumber,
    //             );
    //           }
    //           const collectiblesSold = collectiblesSoldFilter.sort(
    //             (a, b) => b.blockNumber - a.blockNumber,
    //           );
    //           // check item sold event in case any collectible sold.
    //           if (collectiblesSold?.length > 0) {
    //             collectiblesTransfer?.map(async collectibleItem => {
    //               const tokenId = collectibleItem?.args?.tokenId
    //                 ? +collectibleItem?.args?.tokenId
    //                 : null;
    //               if (tokenId) {
    //                 const itemSold = collectiblesSold?.find(
    //                   x =>
    //                     x?.args?._tokenId == tokenId &&
    //                     x?.args?._collection.toLocaleLowerCase() ==
    //                       collectibleAddress?.toLocaleLowerCase(),
    //                 );
    //                 if (itemSold) {
    //                   let collectible = await getCollectibleDetails(
    //                     {
    //                       networkID: collectibleChainId,
    //                       provider: providerToPass,
    //                       collectibleAddress: collectibleAddress,
    //                       address,
    //                       wallet: null,
    //                     },
    //                     { entityData },
    //                     {
    //                       isCache: true,
    //                       isBalanceRefresh: true,
    //                       isMarketPlaceRefresh: false,
    //                     },
    //                   );

    //                   if (collectible) {
    //                     const timestamp = itemSold.timestamp
    //                       ? itemSold.timestamp
    //                       : itemSold.getBlock
    //                         ? getCurrentEpochUnixTimeStamp(
    //                             (await itemSold.getBlock()).timestamp,
    //                           )
    //                         : null;
    //                     //const timestamp = getCurrentEpochUnixTimeStamp((await itemSold.getBlock()).timestamp);

    //                     notifications.push({
    //                       subject: `Congratulations! Your Collectible, ${collectible.name} is sold.`,
    //                       body: `The Collectible, ${collectible.name} #${tokenId}, has been successfully sold in the marketplace.`,
    //                       timeStamp: timestamp,
    //                       id: collectible.name + collectible.address + tokenId,
    //                       notificationType: NotificationType.CollectibleSold,
    //                       dataObject: collectible,
    //                     });
    //                     dispatch(pushNotification(notifications));
    //                   }
    //                 }
    //               }
    //             });
    //           }
    //         }
    //       } catch (e) {}
    //     }
    //   }
    // }

    // // Passport sold
    // if (notificationConfig?.allowance?.passportSold) {
    //   const allAvailablePassports = store.Passports;
    //   const availablePassports =
    //     allAvailablePassports?.AllPassportDetailsList?.filter(
    //       p => p.marketPlaceConfig?.allowMarketPlaceOps,
    //     );
    //   if (availablePassports?.length > 0) {
    //     //get various providers & lastblocknumbers as per available networks
    //     let allOtherNetworks = Object.keys(NetworkId)
    //       .filter(key => !isNaN(Number(NetworkId[key])))
    //       .filter(
    //         n =>
    //           !isNativeChain(NetworkId[n])
    //       );

    //     let allOtherProviders = await Promise.all(
    //       allOtherNetworks
    //         .filter(q =>
    //           availablePassports?.find(p => p.chainId == NetworkId[q]),
    //         )
    //         .map(async n => {
    //           let providerToPass = getStaticLogsProvider(NetworkId[n]);
    //           let lastBlockNumberToPass =
    //             (await providerToPass.getBlockNumber()) - 500;
    //           return {
    //             chainId: NetworkId[n],
    //             provider: providerToPass,
    //             lastBlockNumber: lastBlockNumberToPass,
    //           };
    //         }),
    //     );

    //     const state = getState() as RootState;

    //     let passportSoldChainDetails = [];
    //     let buildPassportTransferReqObj: Array<IBatchRequest> = [];
    //     let buildPassportSoldReqObj: Array<IBatchRequest> = [];
    //     let batchPassportTransferResponse = null;
    //     let batchPassportSoldResponse = null;
    //     //let collectibleAddress;
    //     for (let i = 0; i < availablePassports?.length; i++) {
    //       const passportAddress = availablePassports[i].address;
    //       const passportChainId = Number(availablePassports[i].chainId);

    //       let providerToPass = logsProvider;
    //       let lastBlockNumberToPass;
    //       let isArbitrum = true;

    //       if (!isNativeChain(passportChainId)) {
    //         let otherChainDetails = allOtherProviders.find(
    //           p => p.chainId === passportChainId,
    //         );
    //         if (otherChainDetails) {
    //           isArbitrum = false;
    //           providerToPass = otherChainDetails.provider;
    //           lastBlockNumberToPass = otherChainDetails.lastBlockNumber;
    //         }
    //       }

    //       const blockNumberToPass = !isArbitrum
    //         ? lastBlockNumberToPass
    //         : lastBlockNumber;
    //       passportSoldChainDetails.push({
    //         collectibleAddress: passportAddress,
    //         chainID: passportChainId,
    //         providerToPass: providerToPass,
    //         isArbitrum: isArbitrum,
    //         lastBlockNumberToPass: blockNumberToPass,
    //       });

    //       const loot8Contract = Loot8Collection__factory.connect(
    //         passportAddress,
    //         providerToPass,
    //       );

    //       //const mintTransferParams : IQueryFilterParams = {topics : collectibleContract.filters.Transfer(ethers.constants.AddressZero, address).topics, fromBlock: blockNumberToPass}
    //       let passportTransferReqObj: IBatchRequest = {
    //         id: 'transferQueryFilter: ' + passportAddress,
    //         chainId: passportChainId.toString(),
    //         contractAddress: passportAddress,
    //         functionType: RequestFunctionType.QUERYFILTER,
    //         queryFilterParams: {
    //           fromBlock: '0x' + blockNumberToPass?.toString(16),
    //           topics: loot8Contract.filters.Transfer(address).topics,
    //         },
    //       };
    //       buildPassportTransferReqObj.push(passportTransferReqObj);

    //       const marketplaceContract = Loot8Marketplace__factory.connect(
    //         addresses[passportChainId].MarketPlace,
    //         providerToPass,
    //       );
    //       let passportSoldRequest: IBatchRequest = {
    //         id: 'itemSoldQueryFilter: ' + passportAddress,
    //         chainId: passportChainId.toString(),
    //         contractAddress: addresses[passportChainId].MarketPlace,
    //         functionType: RequestFunctionType.QUERYFILTER,
    //         queryFilterParams: {
    //           fromBlock: '0x' + blockNumberToPass?.toString(16),
    //           topics: marketplaceContract.filters.ItemSold().topics,
    //         },
    //       };
    //       buildPassportSoldReqObj.push(passportSoldRequest);
    //     }

    //     try {
    //       console.log(
    //         'Getting Passport Transfers - Batched - QueryFilter - BlockNumber: ',
    //         lastBlockNumber,
    //       );

    //       batchPassportTransferResponse = await batchExecuteCalls(
    //         buildPassportTransferReqObj,
    //       );
    //     } catch (err) {
    //       LogToLoot8Console(
    //         'NotificationSlice Passport Transfer: batchify-contract-call-',
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //       LogCustomError(
    //         'NotificationSlice Passport Transfer: batchify-contract-call-',
    //         err.name,
    //         err.message,
    //         err.stack,
    //       );
    //     }

    //     for (let i = 0; i < availablePassports?.length; i++) {
    //       const passportAddress = availablePassports[i].address;
    //       const passportChainId = Number(availablePassports[i].chainId);

    //       let providerToPass = logsProvider;
    //       let lastBlockNumberToPass;
    //       let isArbitrum = true;

    //       if (!isNativeChain(passportChainId)) {
    //         let otherChainDetails = allOtherProviders.find(
    //           p => p.chainId === passportChainId,
    //         );
    //         if (otherChainDetails) {
    //           isArbitrum = false;
    //           providerToPass = otherChainDetails.provider;
    //           lastBlockNumberToPass = otherChainDetails.lastBlockNumber;
    //         }
    //       }

    //       try {
    //         let passportsTransferedFilter = [];
    //         const loot8Contract = Loot8Collection__factory.connect(
    //           passportAddress,
    //           providerToPass,
    //         );
    //         const passportTransferResponse =
    //           batchPassportTransferResponse?.length > 0
    //             ? batchPassportTransferResponse.find(
    //                 x =>
    //                   x.id.toLowerCase() ==
    //                   ('transferQueryFilter: ' + passportAddress).toLowerCase(),
    //               )
    //             : null;
    //         if (passportTransferResponse) {
    //           passportsTransferedFilter = passportTransferResponse?.response;
    //         }
    //         if (
    //           providerToPass &&
    //           (isArbitrum === true || lastBlockNumberToPass)
    //         ) {
    //           console.log(
    //             'Getting Passport Transfers - QueryFilter - BlockNumber: ',
    //             lastBlockNumber,
    //           );

    //           passportsTransferedFilter = await loot8Contract.queryFilter(
    //             loot8Contract.filters.Transfer(address),
    //             !isArbitrum ? lastBlockNumberToPass : lastBlockNumber,
    //           );
    //         }
    //         if (passportsTransferedFilter?.length > 0) {
    //           passportsTransferedFilter = await Promise.all(
    //             passportsTransferedFilter.map(pt => {
    //               if (!pt.args) {
    //                 try {
    //                   const passportTransferLogs =
    //                     loot8Contract.interface.parseLog(pt);
    //                   if (passportTransferLogs && passportTransferLogs.args) {
    //                     return { ...pt, args: passportTransferLogs.args };
    //                   }
    //                 } catch (e) {
    //                   LogToLoot8Console(
    //                     'Passport Sold Notification: could not parse event log',
    //                   );
    //                 }
    //               } else {
    //                 return pt;
    //               }
    //             }),
    //           );
    //           const passportsTransfer = passportsTransferedFilter.sort(
    //             (a, b) => b.blockNumber - a.blockNumber,
    //           );

    //           const entityData = state.Entity.EntityData;

    //           try {
    //             console.log(
    //               'Getting Passport Sold - Batched - QueryFilter - BlockNumber: ',
    //               lastBlockNumber,
    //             );

    //             batchPassportSoldResponse = await batchExecuteCalls(
    //               buildPassportSoldReqObj,
    //             );
    //           } catch (err) {
    //             LogToLoot8Console(
    //               'NotificationSlice Passport Sold: batchify-contract-call-',
    //               err.name,
    //               err.message,
    //               err.stack,
    //             );
    //             LogCustomError(
    //               'NotificationSlice Passport Sold: batchify-contract-call-',
    //               err.name,
    //               err.message,
    //               err.stack,
    //             );
    //           }
    //           const processedPassportSoldRes = await processBatchResponse(
    //             batchPassportSoldResponse,
    //             passportSoldChainDetails,
    //             'itemSoldQueryFilter: ',
    //           );

    //           // get passport sold event list
    //           const marketplaceContract = Loot8Marketplace__factory.connect(
    //             addresses[passportChainId].MarketPlace,
    //             providerToPass,
    //           );
    //           let passportsSoldFilter = [];
    //           const passportSoldResponse =
    //             processedPassportSoldRes?.length > 0
    //               ? processedPassportSoldRes.find(
    //                   x =>
    //                     x.id.toLowerCase() ==
    //                     (
    //                       'itemSoldQueryFilter: ' + passportAddress
    //                     ).toLowerCase(),
    //                 )
    //               : null;
    //           if (passportSoldResponse) {
    //             passportsSoldFilter = passportSoldResponse?.response;
    //             if (passportsSoldFilter.length > 0) {
    //               passportsSoldFilter = await Promise.all(
    //                 passportsSoldFilter.map(ps => {
    //                   if (!ps.args) {
    //                     try {
    //                       const passportSoldLogs =
    //                         marketplaceContract.interface.parseLog(ps);
    //                       if (passportSoldLogs && passportSoldLogs.args) {
    //                         return { ...ps, args: passportSoldLogs.args };
    //                       }
    //                     } catch (e) {
    //                       LogToLoot8Console(
    //                         'Passport Sold Notification: could not parse event log',
    //                       );
    //                     }
    //                   } else {
    //                     return ps;
    //                   }
    //                 }),
    //               );
    //             }
    //           } else if (
    //             providerToPass &&
    //             (isArbitrum === true || lastBlockNumberToPass)
    //           ) {
    //             console.log(
    //               'Getting Passport  Sold - QueryFilter - BlockNumber: ',
    //               lastBlockNumber,
    //             );

    //             passportsSoldFilter = await marketplaceContract.queryFilter(
    //               marketplaceContract.filters.ItemSold(),
    //               !isArbitrum ? lastBlockNumberToPass : lastBlockNumber,
    //             );
    //           }
    //           const passportsSold = passportsSoldFilter.sort(
    //             (a, b) => b.blockNumber - a.blockNumber,
    //           );

    //           // check item sold event in case any passport sold.
    //           if (passportsSold?.length > 0) {
    //             passportsTransfer?.map(async passportItem => {
    //               const tokenId = passportItem?.args?.tokenId
    //                 ? +passportItem?.args?.tokenId
    //                 : null;
    //               if (tokenId) {
    //                 const itemSold = passportsSold?.find(
    //                   x =>
    //                     x?.args?._tokenId == tokenId &&
    //                     x?.args?._collection.toLocaleLowerCase() ==
    //                       passportAddress?.toLocaleLowerCase(),
    //                 );
    //                 if (itemSold) {
    //                   let passport = await getCollectibleDetails(
    //                     {
    //                       networkID: passportChainId,
    //                       provider: providerToPass,
    //                       collectibleAddress: passportAddress,
    //                       address,
    //                       wallet: null,
    //                     },
    //                     { entityData },
    //                     {
    //                       isCache: true,
    //                       isBalanceRefresh: true,
    //                       isMarketPlaceRefresh: false,
    //                     },
    //                   );

    //                   if (passport) {
    //                     const timestamp = itemSold.timestamp
    //                       ? itemSold.timestamp
    //                       : itemSold.getBlock
    //                         ? getCurrentEpochUnixTimeStamp(
    //                             (await itemSold.getBlock()).timestamp,
    //                           )
    //                         : null;
    //                     //const timestamp = getCurrentEpochUnixTimeStamp((await itemSold.getBlock()).timestamp);

    //                     notifications.push({
    //                       subject: `Congratulations! Your ExPass, ${passport.name} is sold.`,
    //                       body: `ExPass ${passport.name} #${tokenId}, has been successfully sold in the marketplace.`,
    //                       timeStamp: timestamp,
    //                       id: passport.name + passport.address + tokenId,
    //                       notificationType: NotificationType.PassportSold,
    //                       dataObject: passport,
    //                     });
    //                     dispatch(pushNotification(notifications));

    //                     await dispatch(
    //                       updatePassportDetails({
    //                         address: passport?.address,
    //                         isAvailable: true,
    //                         collectibleIds: passport?.collectibleIds,
    //                         collectibleCount: passport?.collectibleCount,
    //                         passportType: passport?.passportType,
    //                       }),
    //                     );
    //                   }
    //                 }
    //               }
    //             });
    //           }
    //         }
    //       } catch (e) {}
    //     }
    //   }
    // }

    // //dispatch(pushNotification(notifications));

    // if (notifications && notifications.length > 0) {
    //   const lastExecutionTime = getSyncedTime();
    //   await AsyncStorage.setItem(
    //     '@notificationLastExecutionTime',
    //     lastExecutionTime.toString(),
    //   );
    // }
  },
);

// * Not Being Used
export const subscribeToEvents = createAsyncThunk(
  'app/subsribeToEvents',
  async ({
    networkID,
    provider,
    address,
    lastBlockNumber,
    lastTimeStamp,
  }: INotificationAsyncThunk): Promise<any> => {},
);

const _pushNotification = (
  state: NotificationState,
  _notification: INotification | INotification[],
) => {
  let notification: INotification[] = [];
  if (!Array.isArray(_notification)) {
    notification.push(_notification);
  } else {
    notification = _notification;
  }
  let newNotifications = state.notifications.concat(notification);
  const uniqueNotifications = uniqWith(
    newNotifications.sort((a, b) => b.timeStamp - a.timeStamp),
    (itemA: INotification, itemB: INotification) =>
      itemA.id === itemB.id &&
      itemA.notificationType === itemB.notificationType,
  );

  state.notifications = uniqueNotifications;
  storeNotificationsToLocalStorage(state.walletAddress, uniqueNotifications);
};

const storeNotificationsToLocalStorage = async (
  address: string,
  notifications: INotification[],
) => {
  let lastBlockNumber = 0,
    lastTimeStamp = 0;
  if (!notifications) {
    const currStorage = await getData(NOTIFICATIONS + address);
    lastBlockNumber = currStorage.lastBlockNumber;
    lastTimeStamp = currStorage.lastTimeStamp;
  } else {
    const lastBlockNumberRecord = notifications.find(p => p.blockNumber > 0);
    if (lastBlockNumberRecord) {
      lastBlockNumber = lastBlockNumberRecord.blockNumber;
    }

    const lastTimestampRecord = notifications.find(p => p.timeStamp > 0);
    if (lastTimestampRecord) {
      lastTimeStamp = lastTimestampRecord.timeStamp;
    }
  }
  storeData(NOTIFICATIONS + address, {
    lastBlockNumber,
    lastTimeStamp,
    notifications,
  });
};

const initialState: NotificationState = {
  loading: false,
  notifications: [],
  lastBlockNumber: 0,
  lastTimestamp: 0,
  walletAddress: '',
  showNotificationModal: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearNotifications(state) {
      state.notifications = [];
      //storeNotificationsToLocalStorage(state.walletAddress, state.notifications);
    },
    setLastBlockNumber(state, action) {
      state.lastBlockNumber = action.payload;
    },
    setLastTimestamp(state, action) {
      state.lastTimestamp = action.payload;
    },
    setUserWalletAddress(state, action) {
      state.walletAddress = action.payload;
    },
    pushNotification(state, action) {
      _pushNotification(state, action.payload);
    },
    setShowNotificationModal(state, action) {
      state.showNotificationModal = action.payload;
    },
    notificationsShown(state, action) {
      if (action.payload) {
        state.notifications.forEach(noti => {
          if (noti.id == action.payload) {
            noti.shown = true;
          }
        });
        storeNotificationsToLocalStorage(
          state.walletAddress,
          state.notifications,
        );
      } else {
        if (state.notifications && state.notifications.length > 0) {
          state.notifications.forEach(noti => (noti.shown = true));
          storeNotificationsToLocalStorage(
            state.walletAddress,
            state.notifications,
          );
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUnsubsribedNotifications.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        getUnsubsribedNotifications.rejected,
        (state, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'getUnsubsribedNotifications',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(getUnsubsribedNotifications.fulfilled, (state, action) => {
        state.loading = false;
      });
  },
});

export default notificationSlice.reducer;

export const {
  clearNotifications,
  pushNotification,
  setLastBlockNumber,
  setLastTimestamp,
  notificationsShown,
  setUserWalletAddress,
  setShowNotificationModal,
} = notificationSlice.actions;
