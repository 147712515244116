// TODO: add Offers slice data
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';
import {
  convertAreaData,
  isLocationAvailable,
  listTokensOwner,
  setAll,
} from './helpers';

import {
  ICollectibleAsyncThunk,
  IMessageMetaData,
  IOfferAsyncThunk,
  IPassportOfferFactoryAsyncThunk,
  IPassportOfferFactoryBaseAsyncThunk,
} from './interfaces';
import {
  addresses,
  APP_STORAGE_GET_COLLECTIBLEDETAILS,
  APP_STORAGE_GET_PASSPORT_THIRDPARTY_LIST,
  APP_STORAGE_GET_THIRDPARTYCOLLECTDETAILS,
  APP_STORAGE_THIRDPARTY_LIST,
  getAnynetStaticProvider,
  MAX_DATE_TIME,
  NetworkId,
  ZERO_ADDRESS,
  getNetwork,
  getStaticProvider,
  MIN_DATE_TIME,
  getGenesisBlockNumber,
  getStaticLogsProvider,
  APP_STORAGE_SORTED_COLLECTION_LIST,
  getAppConfiguration,
  isNativeChain,
  CategoriesList,
  NETWORKS,
} from '../appconstants';
import { SendMetaTX } from './AppSlice';
// import { GetEventMintMessage, GetOfferMintMessage } from "../helpers/MsgHelper";

import { getIPFSData, getIPFSLink, wait } from '../helpers/ipfs';
import { getData, storeData } from '../helpers/AppStorage';
import {
  NotificationType,
  pushNotification,
} from '../slices/NotificationSlice';
import {
  GetCollectibleMintMessage,
  GetOfferMintedMessage,
  GetOrderReserveMessage,
} from '../helpers/MsgHelper';
import { showToastMessage } from '../helpers/Gadgets';
import {
  LogCustomError,
  LogErrors,
  LogInformation,
} from '../helpers/AppLogger';
import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import { CollectionManager__factory } from '../typechain/factories/CollectionManager__factory';
import { OfferType, UICustomOfferType } from '../enums/offers.enum';
import { CollectionType } from '../enums/collection.enum';
import {
  getAllPassport,
  getMarketPlaceDetails,
  getPassportFinalBuyPrice,
} from './PassportSlice';
import {
  CollectionFactory__factory,
  Dispatcher__factory,
  ExternalCollectionManager__factory,
  Loot8Collection__factory,
  Loot8TieredCouponCollection__factory,
  Loot8UniformCollection__factory,
  SubscriptionManager__factory,
  TokenPriceCalculator__factory,
} from '../typechain';
import { BigNumber, ethers } from 'ethers';
import { getSyncedTime, isActiveTimeStamp } from '../helpers/DateHelper';
import { getPatronRecentReservationForOffer } from './OrderSlice';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { CollectionManagerData } from '../data/CollectionManager';
import { PassportCategory, PassportType } from '../enums/passportCategory.enum';
import { getThirdPartyVerifiedURL } from './AppUserSlice';
import {
  fetchWhitelistedCollections,
  fetchCollectionDetailsCombined,
} from '../helpers/GraphQLHelper';
import { batchExecuteCalls } from '../helpers/APIHelper';
import { IBatchRequest } from '../interfaces/IBatchRequest';
import { formatEther } from 'ethers/lib/utils';

// initilize offers minting variable.
let offersMintingQueue = new Map();

export interface IOffersSliceData {
  readonly FeaturedEntityOffer: any[];
  readonly RegularEntityOffer: any[];
  readonly EventEntityOffer: any[];
  readonly premiumChatCollectibles: any[];
  readonly PassportCollectiables: any[];
  readonly ThirdPartyCollectiables: any[];
  readonly loading: boolean;
  readonly loadingThirdpartyCollecible: boolean;
  readonly allEntityOffer: any[];
  readonly allMintedCoupon: any[];
  readonly allEntityEvents: any[];
}

const initialState: IOffersSliceData = {
  FeaturedEntityOffer: null,
  RegularEntityOffer: null,
  EventEntityOffer: null,
  premiumChatCollectibles: null,
  PassportCollectiables: null,
  ThirdPartyCollectiables: null,
  loading: false,
  loadingThirdpartyCollecible: false,
  allEntityOffer: null,
  allMintedCoupon: null,
  allEntityEvents: null,
};

// * Generic Function for Fetching Owned TokenIds for a Collection
const getTokenIdsForPatron = async (
  networkID,
  provider,
  collectibleAddress,
  address,
  balance,
) => {
  if (balance > 0) {
    // * Support Checking Not Being Used
    // const poapInterfaceID = await getLoot8POAPCollectionInterfaceID();
    // let supported = false;
    // if(poapInterfaceID)
    // {
    //   supported = await loot8Collectible.supportsInterface(poapInterfaceID);
    // }

    let tokens = null;
    if (isNativeChain(networkID)) {
      const colletionManager = await CollectionManager__factory.connect(
        addresses[networkID].CollectionManager,
        provider,
      );

      // * Try/Catch

      try {
        // * Try Getting the Token Ids from the Collection Manager

        // Returns an Array of TokenIds [1, 3, 65]
        tokens = await colletionManager.getAllTokensForPatron(
          collectibleAddress,
          address,
        );

        // Convert to objects with TokenId fields
        tokens = tokens.map(tokenId => {
          // Convert token id from Hex to integer
          tokenId = parseInt(tokenId._hex);
          return { tokenId: tokenId };
        });

        return tokens ? tokens : [];
      } catch (error) {
        console.log('Failed to get tokenOwned from Collection Manager', error);
      }
    } else {
      // * If Network doesnt match
      try {
        // Returns an Array of Token Objects
        // [{tokenId: 1}, {tokenId: 3}, {tokenId: 65}]
        let tokenOwned = await listTokensOwner(
          collectibleAddress,
          networkID,
          address,
        );
        return tokenOwned ? tokenOwned : [];
      } catch (error) {
        console.log('Failed to get tokenOwned from listTokensOwner', error);
      }
    }

    if (!tokens) {
      // * If State fails to return data
      try {
        let tokenOwned = await listTokensOwner(
          collectibleAddress,
          networkID,
          address,
        );
        return tokenOwned ? tokenOwned : [];
      } catch (error) {
        console.log('Failed to get tokenOwned from listTokensOwner', error);
      }
    }
  }
  return [];
};

export const getCollectibleIdsForPatron = async (
  {
    networkID,
    provider,
    collectibleAddress,
    address,
  }: { networkID; provider; collectibleAddress; address },
  isCache = true,
): Promise<any[]> => {
  const collectibles = isCache
    ? await getData(APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress))
    : null;
  if (collectibles?.collectibleCount) {
    return collectibles?.collectibleIds;
  } else {
    const loot8Collectible = Loot8Collection__factory.connect(
      collectibleAddress,
      provider,
    );

    // * Get Balance of User for tokens of this Collection
    const balance = Number(await loot8Collectible.balanceOf(address));
    if (balance > 0) {
      const tokens = await getTokenIdsForPatron(
        networkID,
        provider,
        collectibleAddress,
        address,
        balance,
      );
      return tokens;
    }
    return [];
  }
};

export const sortCollectionLogs = async (networkID, isCache = true) => {
  // Temporarily disabling this feature, as we will be using it from indexer.
  return [];
  // let finalArray = await getData(APP_STORAGE_SORTED_COLLECTION_LIST);

  // if (finalArray?.length > 0 && isCache == true) return finalArray;

  // try {
  //   finalArray = [];
  //   const collectionFactory = CollectionFactory__factory.connect(
  //     addresses[networkID].CollectibleFactory,
  //     getStaticLogsProvider(networkID),
  //   );
  //   console.log(
  //     'Getting Sorted Collection Logs - QUERY FILTER - from block number: ',
  //     getGenesisBlockNumber(networkID),
  //   );
  //   let collectionCreatedEvents = await collectionFactory.queryFilter(
  //     collectionFactory.filters.CreatedCollection(),
  //     getGenesisBlockNumber(networkID),
  //   );
  //   let pushBlock = [];
  //   collectionCreatedEvents?.map((collection, index) => {
  //     const ICollection = new ethers.utils.Interface(
  //       CollectionFactory__factory.abi,
  //     );
  //     const data = ICollection.decodeEventLog(
  //       'CreatedCollection',
  //       collection.data,
  //       collection.topics,
  //     );
  //     const block = collection.blockNumber;
  //     if (block && data && data.length > 1) {
  //       pushBlock.push({ block: block, address: data[1] });
  //     }
  //   });
  //   let sortArray = pushBlock.sort((a, b) => b.block - a.block);
  //   sortArray.map(item => {
  //     finalArray.push(item.address);
  //   });
  // } catch (error) {
  //   LogCustomError(
  //     'sortCollectionLogs',
  //     error.name,
  //     error.message,
  //     error.stack,
  //   );
  // }
  // await storeData(APP_STORAGE_SORTED_COLLECTION_LIST, finalArray);
  // return finalArray;
};

// export const getAllEvent = async ({ networkID, provider }: { networkID, provider }, isCache = true): Promise<any> => {
//   let availableEvents = isCache ? await getData(APP_STORAGE_EVENT_LIST) : null;
//   if (availableEvents) return availableEvents;
//   const collectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);
//   availableEvents = await collectionManager.getAllCollections(CollectionType.EVENT, false);
//   await storeData(APP_STORAGE_EVENT_LIST, availableEvents);
//   return availableEvents;
// }

// export const getAllCollectionForPatron = async ({ networkID, provider, address, collectionType }: { networkID, provider, address, collectionType }, isCache = true, onlyActive = true): Promise<any> => {
//   let availableEvents = isCache ? await getData(APP_STORAGE_GET_PATRONCOLLECTDETAILS(collectionType, address)) : null;
//   if (availableEvents && availableEvents.length > 0) return availableEvents;
//   const collectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);
//   availableEvents = await collectionManager.getAllCollectionsForPatron(collectionType, address, onlyActive);
//   await storeData(APP_STORAGE_GET_PATRONCOLLECTDETAILS(collectionType, address), availableEvents);
//   return availableEvents;
// }

export const getAllThirdParty = async (
  {
    networkID,
    provider,
    entityData,
    address,
    wallet,
  }: { networkID; provider; entityData; address; wallet },
  isCache = true,
): Promise<any> => {
  let availableThirdParty = isCache
    ? await getData(APP_STORAGE_THIRDPARTY_LIST)
    : null;
  if (availableThirdParty && availableThirdParty.length > 0)
    return availableThirdParty;
  try {
    let allpassportList = await getAllPassport({ networkID, provider });
    availableThirdParty = [];
    await Promise.all(
      allpassportList
        .filter(x => x.source !== ZERO_ADDRESS)
        .map(async passportAddress => {
          if (isNativeChain(passportAddress.chainId)) {
            let thirdParty = await getThirdPartyCollectiableListByPassport({
              networkID,
              provider,
              passportAddress: passportAddress.source,
            });
            availableThirdParty = [...availableThirdParty, ...thirdParty];
          }
        }),
    );

    // Fetch External Whitelisted Collectible List
    let extWhilteListedCollectibles =
      await getThirdPartyCollectiableListByPassport({
        networkID,
        provider,
        passportAddress: ethers.constants.AddressZero,
      });
    if (extWhilteListedCollectibles?.length > 0) {
      availableThirdParty = [
        ...availableThirdParty,
        ...extWhilteListedCollectibles,
      ];
    }

    await storeData(APP_STORAGE_THIRDPARTY_LIST, availableThirdParty);
    return availableThirdParty;
  } catch (ex) {
    LogCustomError('getAllThirdParty', ex.name, ex.message, ex.stack);
    return [];
  }
};

const getWhitelistedCollectionsForPassport = async (
  networkID,
  provider,
  passportAddress,
) => {
  let whitelistedCollections = null;
  try {
    let appConfig;
    try {
      appConfig = await getAppConfiguration();
    } catch (err) {
      LogToLoot8Console(
        'getWhitelistedCollectionsForPassport: Error while reading app config',
      );
    }

    if (
      appConfig &&
      appConfig.indexerService &&
      appConfig.indexerService.whitelistedCollections
    ) {
      whitelistedCollections =
        await fetchWhitelistedCollections(passportAddress);
    }
  } catch (err) {
    LogToLoot8Console('Error - getWhitelistedCollectionsForPassport');
    LogToLoot8Console(err);
    LogCustomError(
      'getWhitelistedCollectionsForPassport',
      err.name,
      err.message,
      err.stack,
    );
  }
  //if indexer is disable or any error occured while fetching data from indexer then fetch data from contract
  if (whitelistedCollections === null) {
    const collectionManager = ExternalCollectionManager__factory.connect(
      addresses[networkID].ExternalCollectionManager,
      provider,
    );
    whitelistedCollections =
      await collectionManager.getWhitelistedCollectionsForPassport(
        passportAddress,
      );
  }
  //LogToLoot8Console('whitelistedCollections', whitelistedCollections);
  return whitelistedCollections;
};

export const getThirdPartyCollectiableListByPassport = async (
  {
    networkID,
    provider,
    passportAddress,
  }: { networkID; provider; passportAddress },
  isCache = true,
): Promise<any> => {
  let availableThirdparty = isCache
    ? await getData(APP_STORAGE_GET_PASSPORT_THIRDPARTY_LIST(passportAddress))
    : null;
  if (availableThirdparty && availableThirdparty.length > 0)
    return availableThirdparty;
  try {
    //const collectionManager = ExternalCollectionManager__factory.connect(addresses[networkID].ExternalCollectionManager, provider);
    let tpCollectibles = [];

    //let thirdPartyList = await collectionManager.getWhitelistedCollectionsForPassport(passportAddress);
    // LogToLoot8Console("thirdPartyList", thirdPartyList);
    let thirdPartyList = await getWhitelistedCollectionsForPassport(
      networkID,
      provider,
      passportAddress,
    );
    if (thirdPartyList && thirdPartyList !== null) {
      thirdPartyList.map(thirdParty => {
        if (Boolean(NETWORKS[Number(thirdParty.chainId)].chainId))
          tpCollectibles.push({
            source: thirdParty.source,
            chainId: Number(thirdParty.chainId),
            passportAddress: passportAddress,
          });
      });
      await storeData(
        APP_STORAGE_GET_PASSPORT_THIRDPARTY_LIST(passportAddress),
        tpCollectibles,
      );
    }

    return tpCollectibles;
  } catch (ex) {
    LogCustomError(
      'getThirdPartyCollectiableByPassport-' + passportAddress,
      ex.name,
      ex.message,
      ex.stack,
    );
    return [];
  }
};

export const getThirdPartyCollectiableDetails = async (
  {
    chainId,
    provider,
    address,
    collectiableAddress,
  }: { chainId; provider; address; collectiableAddress },
  isCache = true,
): Promise<any> => {
  let thirdPartyCollectiableData = isCache
    ? await getData(
        APP_STORAGE_GET_THIRDPARTYCOLLECTDETAILS(collectiableAddress, chainId),
      )
    : null;
  if (thirdPartyCollectiableData) return thirdPartyCollectiableData;
  let allTokenData = [];
  try {
    const thirdPartyCollectible = Loot8Collection__factory.connect(
      collectiableAddress,
      getAnynetStaticProvider(chainId),
    );
    let balance = Number(await thirdPartyCollectible.balanceOf(address));

    let tokenOwned;

    if (balance > 0) {
      tokenOwned = await getTokenIdsForPatron(
        chainId,
        provider,
        collectiableAddress,
        address,
        balance,
      );
    }

    if (tokenOwned?.length > 0) {
      for (let token of tokenOwned) {
        let ipfsUri = await thirdPartyCollectible.tokenURI(token?.tokenId);
        let response;
        let isDirectPath = false;
        let ipfsData: any = {};
        try {
          response = await getIPFSData(ipfsUri);
          if (!response && ipfsUri?.indexOf('https') > -1) {
            isDirectPath = true;
            ipfsUri = ipfsUri.replace(/['"]/g, '');
            response = await getIPFSData(ipfsUri, undefined, isDirectPath);
          }
          ipfsData = await response.json();
        } catch (error) {
          LogErrors(
            'getThirdPartyCollectiableDetails-getIPFSData',
            address,
            chainId,
            [
              { tag: 'contract', value: collectiableAddress },
              { tag: 'tokenId', value: token?.tokenId },
            ],
          );
        }

        if (!ipfsData.name) {
          try {
            //token URI data has issues, fetch name from contract.
            ipfsData.name = await thirdPartyCollectible.name();
          } catch (error) {
            LogErrors(
              'getThirdPartyCollectiableDetails-getIPFSDataName',
              address,
              chainId,
              [{ tag: 'contract', value: collectiableAddress }],
            );
          }
        }

        let metaResponse = ipfsData.meta;
        let image = ''; //set blank, so that default image would come up in case image path is not there.
        if (ipfsData.image) image = getIPFSLink(ipfsData.image, isDirectPath);
        let video = '';
        let thumbnail = '';
        let isMp4Collectible = false;
        if (ipfsData?.video) {
          video = getIPFSLink(ipfsData?.video, isDirectPath);
          thumbnail = getIPFSLink(ipfsData?.thumbnail, isDirectPath);
          isMp4Collectible = true;
        }
        let thumbnailImage =
          ipfsData && ipfsData?.thumbnailImage
            ? getIPFSLink(ipfsData?.thumbnailImage, isDirectPath)
            : '';
        let optimizedImage =
          ipfsData && ipfsData?.optimizedImage
            ? getIPFSLink(ipfsData?.optimizedImage, isDirectPath)
            : '';
        let thumbnailImageSize =
          ipfsData && ipfsData?.thumbnailImageSize
            ? ipfsData?.thumbnailImageSize
            : null;
        let optimizedImageSize =
          ipfsData && ipfsData?.optimizedImageSize
            ? ipfsData?.optimizedImageSize
            : null;

        let interactiveCollectible = false;
        let animationUrl = '';
        if (ipfsData?.animation_url) {
          animationUrl =
            ipfsData.animation_url.indexOf('ipfs://') > -1
              ? getIPFSLink(ipfsData.animation_url, isDirectPath)
              : ipfsData.animation_url;
          //thumbnail = getIPFSLink(ipfsData.thumbnail)
          interactiveCollectible = true;
        }
        allTokenData.push({
          tokenId: token?.tokenId,
          collectibleId: token?.tokenId,
          image: image,
          video: video,
          thumbnail: thumbnail,
          isMp4Collectible: isMp4Collectible,
          is3rdPartyCollectible: true,
          name: ipfsData?.name,
          address: collectiableAddress,
          catalogCount: balance,
          details: ipfsData?.description,
          subTitle: token?.tokenId ? '#' + token?.tokenId : '',
          chainId: chainId,
          isActive: true,
          // * Not Used
          // or used for Sorting atmost. Not needed right now
          timestamp: 0,
          isThirdParty: true,
          interactiveCollectible,
          animationUrl,
          thumbnailImage: thumbnailImage,
          thumbnailImageSize: thumbnailImageSize,
          optimizedImage: optimizedImage,
          optimizedImageSize: optimizedImageSize,
        });
      }
      await storeData(
        APP_STORAGE_GET_THIRDPARTYCOLLECTDETAILS(collectiableAddress, chainId),
        allTokenData,
      );
    } else {
      try {
        if (thirdPartyCollectible) {
          const name = await thirdPartyCollectible.name();
          if (name) {
            allTokenData.push({
              is3rdPartyCollectible: true,
              name: name,
              address: collectiableAddress,
              catalogCount: balance,
              chainId: chainId,
              isThirdParty: true,
            });
          }
        }
      } catch (error) {
        LogErrors(
          'getThirdPartyCollectiableDetails-collectibleNotOwnedByUser',
          address,
          chainId,
          [{ tag: 'contract', value: collectiableAddress }],
        );
      }
    }
  } catch (ex) {
    LogCustomError(
      'getThirdPartyCollectiableDetails-' + collectiableAddress,
      ex.name,
      ex.message,
      ex.stack,
    );
  } finally {
    return allTokenData;
  }
};

export const getCollectibleDetails = async (
  {
    networkID,
    provider,
    collectibleAddress,
    address,
    wallet,
    callTokenOwner = true,
  }: ICollectibleAsyncThunk,
  { entityData }: { entityData },
  { isCache = true, isBalanceRefresh = false, isMarketPlaceRefresh = false },
  subscribedPassportList = [],
): Promise<any> => {
  const isSupportedChain = Boolean(NETWORKS[networkID].chainId);

  //* Return an empty object if the chain is INVALID to prevent Exceptions/Crashes
  if (!isSupportedChain) {
    return {
      dataURI: '',
      image: '',
      details: '',
      name: '',
      address: '',
      symbol: '',
      isActive: false,
      area: undefined,
      entityAddress: '',
      price: 0,
      start: 0,
      end: 0,
      checkInNeeded: false,
      offerType: OfferType.NOTANOFFER,
      collectionType: CollectionType.ANY,
      collectibleCount: 0,
      collectibleIds: [],
      backgroundImage: '',
      linkCollectible: [],
      subTitle: '',
      tokensEarned: 0,
      customOfferType: UICustomOfferType.EMPTY,
      whitelistedCollections: [],
      isAvailable: false,
      timestamp: null,
      chainId: networkID,
      isCoupon: false,
      tokenId: '',
      isOrderPlaced: false,
      isMp4Collectible: false,
      video: null,
      tierCollections: [],
      marketPlaceConfig: {
        privateTradeability: false,
        publicTradeability: false,
        allowMarketPlaceOps: false,
        listingExists: false,
        listingIndex: null,
      },
      thirdPartyVerifiedURL: [],
      followerCount: 0,
      isPremium: null,
      category: CategoriesList[0].name,
    };
  }

  // initialise constants
  const arbChainId = getNetwork();
  const arbProvider = getStaticProvider();

  const collectibleContract = Loot8Collection__factory.connect(
    collectibleAddress,
    provider,
  );
  const collectionManager = CollectionManager__factory.connect(
    addresses[arbChainId].CollectionManager,
    arbProvider,
  );
  const tokenPriceCalcultor = TokenPriceCalculator__factory.connect(
    addresses[arbChainId].TokenPriceCalculator,
    arbProvider,
  );

  // fetch cached data
  const localStoredData = await getData(
    APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress),
  );
  let collectibleDetail = isCache ? localStoredData : null;

  if (
    collectibleDetail &&
    collectibleDetail?.collectionType == CollectionType.PASSPORT
  ) {
    try {
      // Fetch Collection data directly from the chain and set the isPremium Flag
      const collectionInfoFromChain =
        await collectionManager.getCollectionInfo(collectibleAddress);
      collectibleDetail.isPremium =
        collectionInfoFromChain?._additionCollectionData?.isPremium;
      const categoryObj = CategoriesList.find(
        c =>
          c.id === collectionInfoFromChain?._additionCollectionData?.category,
      );
      collectibleDetail.category = categoryObj?.name || CategoriesList[0].name;

      const subscriptionManager = SubscriptionManager__factory.connect(
        addresses[getNetwork()].SubscriptionManager,
        getStaticProvider(),
      );

      // Collection Supply is the number of subscriptions for a collection
      // ? also Number of Subscribers
      const subscribers =
        await subscriptionManager.collectionSupply(collectibleAddress);

      // Convert BigNumber to Number
      collectibleDetail.followerCount = subscribers.toNumber();
    } catch (e) {
      LogToLoot8Console('Offerslice-getCollectibleDetails-Subscriber Count', e?.message);
    }
  }

  if (collectibleDetail && !isBalanceRefresh) {
    // update marketplace for collection if necessary when fetching from cache
    if (
      collectibleDetail.collectibleIds.length > 0 &&
      (collectibleDetail.collectionType === CollectionType.COLLECTION ||
        collectibleDetail.collectionType === CollectionType.PASSPORT) &&
      isMarketPlaceRefresh
    ) {
      collectibleDetail.marketPlaceConfig = await getMarketPlaceDetails(
        collectibleAddress,
        collectibleDetail?.collectibleCount,
        collectibleDetail.chainId,
      );
    }

    if (collectibleDetail.interactiveCollectible) {
      let newDataURI = null;

      if (!isNativeChain(networkID)) {
        const newLoot8Collectible = Loot8UniformCollection__factory.connect(
          collectibleAddress,
          provider,
        );
        const newContractURI = await newLoot8Collectible.contractURI();
        if (newContractURI && newContractURI !== collectibleDetail?.dataURI) {
          newDataURI = newContractURI;
        }
      } else {
        let collData =
          await collectionManager.getCollectionInfo(collectibleAddress);
        if (
          collData?._dataURI &&
          collData?._dataURI !== collectibleDetail?.dataURI
        ) {
          newDataURI = collData?._dataURI;
        }
      }
      if (newDataURI) {
        // in case of html or other interactive collectible, we need to refresh latest html
        collectibleDetail.dataURI = newDataURI;
        let response = await getIPFSData(newDataURI);
        let ipfsData = await response?.json();
        if (ipfsData?.animation_url) {
          collectibleDetail.animationUrl =
            ipfsData.animation_url.indexOf('ipfs://') > -1
              ? getIPFSLink(ipfsData.animation_url)
              : ipfsData.animation_url;
          //collectibleDetail.thumbnail = getIPFSLink(ipfsData?.thumbnail);
          collectibleDetail.interactiveCollectible = true;
        }
      }
    }

    // update and return the cached data
    await storeData(
      APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress),
      collectibleDetail,
    );

    return collectibleDetail;
  }
  let collectible: ICollectibleDetail = null;

  let collectionData,
    collectionAdditionalData,
    collectionMetaData,
    collectionLinkedData;
  let appConfig = await getAppConfiguration();
  if (
    appConfig &&
    appConfig.indexerService &&
    appConfig.indexerService.collectibleDetails
  ) {
    const combinedData =
      await fetchCollectionDetailsCombined(collectibleAddress);
    collectionData = combinedData?.collectionData; // await fetchCollectionData(collectibleAddress);
    collectionAdditionalData = combinedData?.collectionDataAdditional; // await fetchCollectionAdditionalData(collectibleAddress);
    collectionMetaData = combinedData?.collectionMetadata; // await fetchCollectionMetaData(collectibleAddress);
    collectionLinkedData = combinedData?.collectionLinkedData;

    // Fetch Linked Collections from the chain itself as Indexer can be out of sync
    try {
      const colInfo =
        await collectionManager.getCollectionInfo(collectibleAddress);
      collectionMetaData.linkedCollections = JSON.stringify(
        colInfo?._linkedCollections,
      );
    } catch (error) {}

    // const filteredLinkedCollectionsAddresses = collectionLinkedData?.map(x =>
    //   x.collectibleOne === collectibleAddress
    //     ? x.collectibleTwo
    //     : x.collectibleOne,
    // );
    // try {
    //   collectionMetaData.linkedCollections = JSON.stringify(
    //     filteredLinkedCollectionsAddresses,
    //   );
    // } catch (error) {}
  }

  // get blocks timestamp if token minted for user
  let timestamp = null;
  let tokenId = 0;
  let tokenOwned = [];
  if (callTokenOwner) {
    tokenOwned = await getCollectibleIdsForPatron(
      { networkID: networkID, provider: provider, collectibleAddress, address },
      isCache && !isBalanceRefresh,
    );
    if (tokenOwned && tokenOwned.length > 0) {
      (timestamp = tokenOwned[0]?.timestamp),
        (tokenId = tokenOwned[0]?.tokenId
          ? Number(tokenOwned[0]?.tokenId)
          : tokenId);
    }
  } else {
    timestamp = localStoredData?.timestamp;
    tokenId = localStoredData?.tokenId ?? 0;
  }

  if (collectibleDetail != null && isBalanceRefresh) {
    try {
      collectibleDetail.collectibleCount = Number(tokenOwned?.length);
      collectibleDetail.collectibleIds = [];
      if (collectibleDetail?.collectibleCount > 0) {
        // let tokenList = await getCollectibleIdsForPatron({networkID, provider, collectibleAddress, address}, false);
        // collectibleDetail.collectibleIds = tokenList?.length > 0 ? tokenList.map(x => { return Number(x) }) : [];
        collectibleDetail.collectibleIds =
          tokenOwned?.length > 0
            ? tokenOwned.map(x => {
                return Number(x.tokenId);
              })
            : [];
      }
      collectibleDetail.subTitle =
        collectibleDetail?.collectibleIds?.length > 0
          ? '#' + collectibleDetail.collectibleIds[0]
          : '';
      collectibleDetail.timestamp = timestamp;
      collectibleDetail.tokenId = tokenId;
      // offer order is reserved by user then hide it from UI.
      if (
        collectibleDetail.collectionType == CollectionType.OFFER &&
        collectibleDetail.isCoupon &&
        collectibleDetail.collectibleCount > 0
      ) {
        try {
          const orderPlaced = await getPatronRecentReservationForOffer({
            networkID: arbChainId,
            provider: arbProvider,
            address: address,
            offerAddress: collectibleAddress,
          });
          if (+orderPlaced > 0) {
            collectibleDetail.isAvailable = false;
            collectibleDetail.isActive = false;
          }
        } catch (e) {
          LogToLoot8Console('getPatronRecentReservationForOffer', e);
        }
      }
      collectibleDetail.collectionCollectibleIds =
        +(await collectibleContract.collectionCollectibleIds());
      if (
        collectibleDetail.passportType === PassportType.SUBSCRIPTION &&
        collectibleDetail.collectibleIds.length > 0
      ) {
        collectibleDetail.passportType = PassportType.SUBSCRIBED;
        collectibleDetail.initialBuyPrice = collectibleDetail.buyPrice;
      }
      if (
        collectibleDetail.passportType === PassportType.SUBSCRIBED &&
        collectibleDetail.collectibleIds.length === 0
      ) {
        collectibleDetail.passportType = PassportType.SUBSCRIPTION;
        collectibleDetail.initialBuyPrice = null;
      }

      if (
        collectibleDetail.collectibleIds.length > 0 &&
        (collectibleDetail.collectionType === CollectionType.COLLECTION ||
          collectibleDetail.collectionType === CollectionType.PASSPORT) &&
        isMarketPlaceRefresh
      ) {
        collectibleDetail.marketPlaceConfig = await getMarketPlaceDetails(
          collectibleAddress,
          collectibleDetail?.collectibleCount,
          collectibleDetail.chainId,
        );
      }

      await storeData(
        APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress),
        collectibleDetail,
      );
      // LogInformation(
      //   'Offerslice/getCollectibleDetails/balanceRefresh' + collectibleAddress,
      //   address,
      //   networkID,
      //   [
      //     { tag: 'name', value: collectible?.name },
      //     {
      //       tag: 'collectibleIds.length',
      //       value: collectibleDetail?.collectibleIds?.length?.toString(),
      //     },
      //     { tag: 'cache', value: isCache },
      //     { tag: 'balanceRefresh', value: isBalanceRefresh },
      //     { tag: 'marketPlaceRefresh', value: isMarketPlaceRefresh },
      //   ],
      // );

      return collectibleDetail;
    } catch (ex) {
      LogCustomError(
        'getCollectibleDetails-balance-' + collectibleAddress,
        ex.name,
        ex.message,
        ex.stack,
      );
    } finally {
      return collectibleDetail;
    }
  }

  // batchify api requests
  let batchResponse = null;
  if (appConfig && appConfig.batchRequestEnable) {
    try {
      let buildResquestObj: Array<IBatchRequest> = [];

      let batchContractDataRequest: IBatchRequest = {
        id: 'getCollectionInfo',
        chainId: arbChainId.toString(),
        contractAddress: addresses[arbChainId].CollectionManager,
        interface: collectionManager.interface,
        functionName: 'getCollectionInfo',
        params: [collectibleAddress],
      };
      buildResquestObj.push(batchContractDataRequest);

      let batchCollectibleIdsRequest: IBatchRequest = {
        id: 'collectionCollectibleIds',
        chainId: networkID.toString(),
        contractAddress: collectibleAddress,
        interface: collectibleContract.interface,
        functionName: 'collectionCollectibleIds',
        params: null,
      };
      buildResquestObj.push(batchCollectibleIdsRequest);

      let batchCollectibleName = null;
      let batchCollectibleSymbol = null;
      let batchCollectibledataURI = null;

      if (
        !isNativeChain(networkID)
      ) {
        const loot8Collectible = Loot8UniformCollection__factory.connect(
          collectibleAddress,
          provider,
        );
        batchCollectibleName = {
          id: 'collectibleName',
          chainId: networkID.toString(),
          contractAddress: collectibleAddress,
          interface: loot8Collectible.interface,
          functionName: 'name',
          params: null,
        };
        buildResquestObj.push(batchCollectibleName);

        batchCollectibleSymbol = {
          id: 'collectibleSymbol',
          chainId: networkID.toString(),
          contractAddress: collectibleAddress,
          interface: loot8Collectible.interface,
          functionName: 'symbol',
          params: null,
        };
        buildResquestObj.push(batchCollectibleSymbol);

        batchCollectibledataURI = {
          id: 'contractURI',
          chainId: networkID.toString(),
          contractAddress: collectibleAddress,
          interface: loot8Collectible.interface,
          functionName: 'contractURI',
          params: null,
        };
        buildResquestObj.push(batchCollectibledataURI);
      }

      batchResponse = await batchExecuteCalls(buildResquestObj);
    } catch (err) {
      LogCustomError(
        'batchify-contract-call-' + collectibleAddress,
        err.name,
        err.message,
        ' ',
      );
    }
  }
  try {
    let collectibleData, collectibleName, collectibleSymbol;
    if (!collectionData || !collectionAdditionalData || !collectionMetaData) {
      const batchGetCollectionInfoResponse =
        batchResponse?.length > 0
          ? batchResponse.find(
              x => x.id.toLowerCase() == 'getCollectionInfo'.toLowerCase(),
            )
          : null;
      collectibleData = batchGetCollectionInfoResponse
        ? batchGetCollectionInfoResponse?.response
        : await collectionManager.getCollectionInfo(collectibleAddress);
    }

    collectibleName = collectionMetaData?.name ?? collectibleData?._name;
    collectibleSymbol = collectionMetaData?.symbol ?? collectibleData?._symbol;

    collectible = {
      dataURI: '',
      image: '',
      details: '',
      name: '',
      address: '',
      symbol: '',
      isActive: false,
      area: undefined,
      entityAddress: '',
      price: 0,
      start: 0,
      end: 0,
      checkInNeeded: false,
      offerType: OfferType.NOTANOFFER,
      collectionType: CollectionType.ANY,
      collectibleCount: 0,
      collectibleIds: [],
      backgroundImage: '',
      linkCollectible: [],
      subTitle: '',
      tokensEarned: 0,
      customOfferType: UICustomOfferType.EMPTY,
      whitelistedCollections: [],
      isAvailable: false,
      timestamp: null,
      chainId: networkID,
      isCoupon: false,
      tokenId: tokenId,
      isOrderPlaced: false,
      isMp4Collectible: false,
      video: null,
      tierCollections: [],
      marketPlaceConfig: {
        privateTradeability: false,
        publicTradeability: false,
        allowMarketPlaceOps: false,
        listingExists: false,
        listingIndex: null,
      },
      thirdPartyVerifiedURL: [],
      followerCount: 0,
      isPremium: null,
      category: CategoriesList[0].name,
    };
    collectible.dataURI =
      collectionMetaData?.dataURI ?? collectibleData?._dataURI;

    if (
      !isNativeChain(networkID)
    ) {
      const loot8Collectible = Loot8UniformCollection__factory.connect(
        collectibleAddress,
        provider,
      );
      const batchCollectibleNameResponse =
        batchResponse?.length > 0
          ? batchResponse.find(
              x => x.id.toLowerCase() == 'collectibleName'.toLowerCase(),
            )
          : null;
      const batchCollectibleSymbolResponse =
        batchResponse?.length > 0
          ? batchResponse.find(
              x => x.id.toLowerCase() == 'collectibleSymbol'.toLowerCase(),
            )
          : null;
      const batchContractURIResponse =
        batchResponse?.length > 0
          ? batchResponse.find(
              x => x.id.toLowerCase() == 'contractURI'.toLowerCase(),
            )
          : null;

      collectibleName = batchCollectibleNameResponse
        ? batchCollectibleNameResponse?.response[0]
        : await loot8Collectible.name();
      collectibleSymbol = batchCollectibleSymbolResponse
        ? batchCollectibleSymbolResponse?.response[0]
        : await loot8Collectible.symbol();
      collectible.dataURI = batchContractURIResponse
        ? batchContractURIResponse?.response[0]
        : await loot8Collectible.contractURI();
    }

    let response =
      collectible.dataURI && collectible.dataURI.length > 0
        ? await getIPFSData(collectible.dataURI)
        : undefined;
    let ipfsData = await response?.json();
    collectible.image = ipfsData ? getIPFSLink(ipfsData?.image) : '';
    collectible.thumbnailImage =
      ipfsData && ipfsData?.thumbnailImage
        ? getIPFSLink(ipfsData?.thumbnailImage)
        : '';
    collectible.optimizedImage =
      ipfsData && ipfsData?.optimizedImage
        ? getIPFSLink(ipfsData?.optimizedImage)
        : '';
    // set video file if available
    if (ipfsData?.video) {
      collectible.video = getIPFSLink(ipfsData?.video);
      collectible.thumbnail = getIPFSLink(ipfsData?.thumbnail);
      collectible.isMp4Collectible = true;
    }

    if (ipfsData?.animation_url) {
      collectible.animationUrl =
        ipfsData.animation_url.indexOf('ipfs://') > -1
          ? getIPFSLink(ipfsData.animation_url)
          : ipfsData.animation_url;
      //collectible.thumbnail = getIPFSLink(ipfsData.thumbnail);
      collectible.interactiveCollectible = true;
    }

    let socialLinks = {
      discord: ipfsData?.discord,
      telegram: ipfsData?.telegram,
      facebook: ipfsData?.facebook,
      instagram: ipfsData?.instagram,
      twitter: ipfsData?.twitter,
      tiktok: ipfsData?.tiktok,
    };

    collectible.details = ipfsData?.description;
    collectible.socialLinks = socialLinks;
    //collectible.discord = ipfsData?.discord;
    //collectible.telegram = ipfsData?.telegram;
    collectible.imageSize = ipfsData?.imageSize ? ipfsData?.imageSize : null;
    collectible.thumbnailImageSize = ipfsData?.thumbnailImageSize
      ? ipfsData?.thumbnailImageSize
      : null;
    collectible.optimizedImageSize = ipfsData?.optimizedImageSize
      ? ipfsData?.optimizedImageSize
      : null;
    collectible.name = collectibleName;
    collectible.address = collectibleAddress;
    collectible.symbol = collectibleSymbol;
    collectible.isActive =
      collectionMetaData?.isActive ?? collectibleData?._isActive;
    collectible.area = convertAreaData(
      collectionMetaData?.areaPoints
        ? JSON.parse(collectionMetaData?.areaPoints)
        : collectibleData._areaPoints,
      collectionMetaData?.areaRadius
        ? Number(collectionMetaData?.areaRadius)
        : collectibleData._areaRadius,
    );
    collectible.entityAddress =
      collectionData?.entity ?? collectibleData?._data?.entity;
    let price = Number(collectionData?.price ?? collectibleData?._data?.price);
    collectible.price = price / Math.pow(10, 18);
    collectible.start =
      Number(collectionData?.start ?? collectibleData?._data?.start) * 1000; //blocktimestamp to timestamp
    collectible.end =
      Number(collectionData?.end ?? collectibleData?._data?.end) * 1000; //blocktimestamp to timestamp
    collectible.checkInNeeded =
      collectionData?.checkInNeeded ?? collectibleData?._data?.checkInNeeded;
    collectible.offerType =
      collectionData?.offerType ?? collectibleData?._data?.offerType;

    // Fetch Linked Collections from the chain itself as Indexer can be out of sync
    try {
      if (collectionMetaData?.linkedCollections) {
        collectible.linkCollectible = JSON.parse(
          collectionMetaData?.linkedCollections,
        );
      } else {
        const colInfo =
          await collectionManager.getCollectionInfo(collectibleAddress);
        collectible.linkCollectible = colInfo?._linkedCollections;
      }
    } catch (error) {}

    // collectible.linkCollectible = collectionMetaData?.linkedCollections
    //   ? JSON.parse(collectionMetaData?.linkedCollections)
    //   : collectibleData?._linkedCollections;
    collectible.collectionType =
      collectionMetaData?.collectionType ?? collectibleData?._collectionType;
    collectible.collectibleCount = tokenOwned?.length;
    // const batchBalanceOfResponse = batchResponse?.length > 0 ? batchResponse.find(x => x.id.toLowerCase() == "balanceOf".toLowerCase()) : null;
    // collectible.collectibleCount = batchBalanceOfResponse ? Number(batchBalanceOfResponse?.response) : Number(await collectibleContract.balanceOf(address));
    const batchCollectionCollectibleIdsResponse =
      batchResponse?.length > 0
        ? batchResponse.find(
            x => x.id.toLowerCase() == 'collectionCollectibleIds'.toLowerCase(),
          )
        : null;
    collectible.collectionCollectibleIds = batchCollectionCollectibleIdsResponse
      ? +batchCollectionCollectibleIdsResponse?.response
      : +(await collectibleContract.collectionCollectibleIds());
    collectible.maxTokens = collectionData?.maxMint
      ? Number(collectionData?.maxMint)
      : +collectibleData?._data.maxMint;
    collectible.maxPurchase = collectionData?.maxPurchase
      ? Number(collectionData?.maxPurchase)
      : +collectibleData?._data.maxPurchase;
    collectible.maxBalance = collectionAdditionalData?.maxBalance
      ? Number(collectionAdditionalData?.maxBalance)
      : Number(collectibleData._additionCollectionData?.maxBalance);
    collectible.mintWithLinkedOnly =
      collectionAdditionalData?.mintWithLinkedOnly ??
      collectibleData._additionCollectionData?.mintWithLinkedOnly;
    let isCoupon =
      collectionAdditionalData?.isCoupon ??
      +collectibleData?._additionCollectionData?.isCoupon;
    collectible.isCoupon = isCoupon == 1 ? true : false;

    if (collectible.collectionType == CollectionType.PASSPORT) {
      let buyPrice = 0;
      collectible.buyPrice = buyPrice;
      //if the passport is regular passport or subscription passport
      let mintModel =
        collectionAdditionalData?.mintModel ??
        collectibleData._additionCollectionData?.mintModel;
      if (mintModel === PassportType.REGULAR) {
        collectible.passportType = PassportType.REGULAR;
      } else if (mintModel === PassportType.SUBSCRIPTION) {
        collectible.passportType = PassportType.SUBSCRIPTION;
        try {
          collectible.buyPrice =
            await getPassportFinalBuyPrice(collectibleAddress);
        } catch (ex) {
          LogCustomError(
            'getCollectibleDetails: getBuyPrice' + collectibleAddress,
            ex.name,
            ex.message,
            ex.stack,
          );
        }
      }
      //If passport is Organizational passprot or People passport
      if (
        entityData &&
        entityData[collectible?.entityAddress]?.isOnboardedEntity
      ) {
        collectible.passportCategory = PassportCategory.PEOPLE;
      } else {
        collectible.passportCategory = PassportCategory.ORGANIZATION;
      }
    }

    if (collectible.start && !collectible.end) {
      //start date is mentioned but no end date, lets take maximum date
      collectible.end = MAX_DATE_TIME; // Sat Sep 13 275760 05:30:00 GMT+0530
    } else if (collectible.end && !collectible.start) {
      //end date is mentioned but no start date, lets take minimum start date
      collectible.start = MIN_DATE_TIME; // Mon Jan 01 1900 00:00:00 GMT+0521
    }

    if (
      collectible.start &&
      collectible.end &&
      !collectible.collectibleCount &&
      !isActiveTimeStamp(collectible.start, collectible.end)
    ) {
      /* if collectible is not within time frame and user has zero balance then
       it becomes unavailable passport for user */
      collectible.isAvailable = false;
    }
    /* if the collectible doesnt have time frame and not yet minted to user 
      and available in given geo-fence then it is available to user*/
    if (
      !collectible.start &&
      !collectible.end &&
      !collectible.collectibleCount
    ) {
      collectible.isAvailable = isLocationAvailable(null, collectible.area);
    }

    //TODO: Keval
    //collectibleids only get ids for collection within our system...
    collectible.collectibleIds = [];
    if (collectible?.collectibleCount > 0) {
      // let tokenList = await getCollectibleIdsForPatron({networkID, provider, collectibleAddress, address});
      // collectible.collectibleIds = tokenList?.length > 0 ? tokenList.map(x => { return Number(x) }) : [];
      collectible.collectibleIds =
        tokenOwned?.length > 0
          ? tokenOwned.map(x => {
              return Number(x?.tokenId);
            })
          : [];
    }
    collectible.subTitle =
      collectible.collectibleIds && collectible.collectibleIds.length > 0
        ? '#' + collectible.collectibleIds[0]
        : '';
    collectible.backgroundImage = entityData
      ? entityData[collectible?.entityAddress]?.backgroundImage
      : null;
    if (
      collectible.passportType === PassportType.SUBSCRIPTION &&
      collectible.collectibleIds.length > 0
    ) {
      collectible.passportType = PassportType.SUBSCRIBED;
      //fetch initial subscription price for subscribed passports
      if (subscribedPassportList && subscribedPassportList?.length > 0) {
        let subscribedPassport = subscribedPassportList.find(
          p =>
            p.trader.toLowerCase() === address.toLowerCase() &&
            p.passport.toLowerCase() === collectibleAddress.toLowerCase() &&
            p.transfers[0].tokenId?.toString() ===
              collectible.collectibleIds[0].toString(),
        );
        if (
          subscribedPassport &&
          subscribedPassport?.price &&
          subscribedPassport?.price !== null
        ) {
          collectible.initialBuyPrice =
            subscribedPassport.price / Math.pow(10, 18);
        }
      }
    }

    if (
      collectible.collectionType == CollectionType.PASSPORT &&
      collectible.passportType !== PassportType.SUBSCRIPTION &&
      collectible.collectibleIds.length > 0
    ) {
      collectible.isAvailable = true;
      //Passport is minted withing Time bound, so make it available even though not on location
    }

    if (
      collectible.collectionType == CollectionType.COLLECTION &&
      collectible.collectibleIds.length > 0
    ) {
      // set active flag true if collectible is minted to user account.
      collectible.isActive = true;
    }

    if (
      collectible.collectibleIds.length > 0 &&
      (collectible.collectionType === CollectionType.COLLECTION ||
        collectible.collectionType === CollectionType.PASSPORT)
    ) {
      // get marketplace config for collectibles
      collectible.marketPlaceConfig = await getMarketPlaceDetails(
        collectibleAddress,
        collectible?.collectibleCount,
        collectible.chainId,
      );
    }

    collectible.tokensEarned = 0;
    if (collectible.collectionType == CollectionType.TICKET)
      collectible.customOfferType = UICustomOfferType.TICKET;
    if (collectible.collectionType == CollectionType.OFFER) {
      if (collectible.offerType == OfferType.FEATURED)
        collectible.customOfferType = UICustomOfferType.FEATURED_OFFER;
      else if (collectible.offerType == OfferType.REGULAR)
        collectible.customOfferType = UICustomOfferType.REGULAR;
    }
    if (
      collectible.collectionType == CollectionType.PASSPORT &&
      collectible.passportType !== PassportType.SUBSCRIPTION
    ) {
      if (collectible.isAvailable && collectible.collectibleIds.length == 0) {
        collectible.isAvailable = false;
      }
      if (isNativeChain(networkID)) {
        collectible.whitelistedCollections =
          await getThirdPartyCollectiableListByPassport(
            {
              networkID,
              provider,
              passportAddress: collectibleContract.address,
            },
            isCache,
          );
      } else {
        collectible.whitelistedCollections = [];
      }
    }

    // Coupon is not minted withing time-bound or geo-fence and external criteria, its going to hide from UI.
    if (
      collectible.collectionType == CollectionType.OFFER &&
      collectible.isCoupon &&
      collectible.collectibleCount == 0
    ) {
      collectible.isAvailable = false;
    }

    // offer order is reserved by user then hide it from UI.
    if (
      collectible.collectionType == CollectionType.OFFER &&
      collectible.isCoupon &&
      collectible.collectibleCount > 0 &&
      tokenId
    ) {
      try {
        let collectibleDetails = await collectionManager.collectibleDetails(
          collectibleAddress,
          tokenId,
        );
        if (collectibleDetails?.redeemed) {
          collectible.isAvailable = false;
          // make inactive coupon in case order placed.
          collectible.isActive = false;
          collectible.isOrderPlaced = true;
        } else {
          collectible.isOrderPlaced = false;
        }
        // const orderPlaced = await getPatronRecentReservationForOffer({ networkID: arbChainId, provider: arbProvider, address: address, offerAddress: collectibleAddress })
        // if(+orderPlaced > 0) {
        //   collectible.isAvailable = false;
        //   // inactive coupon in case order placed.
        //   collectible.isActive = false;
        //   collectible.isOrderPlaced = true;
        // } else {
        //   collectible.isOrderPlaced = false;
        // }
      } catch (e) {
        LogToLoot8Console('getPatronRecentReservationForOffer', e);
      }
    }

    if (
      collectible.collectionType == CollectionType.OFFER &&
      collectible.isCoupon
    ) {
      const collectibleContract = Loot8TieredCouponCollection__factory.connect(
        collectibleAddress,
        provider,
      );
      const tierCollection = await collectibleContract.tierCollection();
      if (tierCollection) {
        collectible.tierCollections.push(tierCollection);
      }
    }

    if (collectible.collectionType == CollectionType.PASSPORT) {
      // Get Followers/Subscribers Count
      try {
        // Fetch Collection data directly from the chain and set the isPremium Flag
        const collectionInfoFromChain =
          await collectionManager.getCollectionInfo(collectibleAddress);
        collectible.isPremium =
          collectionInfoFromChain?._additionCollectionData?.isPremium;
        const categoryObj = CategoriesList.find(
          c =>
            c.id === collectionInfoFromChain?._additionCollectionData?.category,
        );
        collectible.category = categoryObj?.name || CategoriesList[0].name;

        const subscriptionManager = SubscriptionManager__factory.connect(
          addresses[getNetwork()].SubscriptionManager,
          getStaticProvider(),
        );

        // Collection Supply is the number of subscriptions for a collection
        // ? also Number of Subscribers
        const subscribers =
          await subscriptionManager.collectionSupply(collectibleAddress);

        // Convert BigNumber to Number
        collectible.followerCount = subscribers.toNumber();
      } catch (e) {
        LogToLoot8Console(
          'Offerslice-getCollectibleDetails-Subscriber Count',
          e?.message,
        );
      }
    }

    collectible.timestamp = timestamp;

    // pull collection owner who creted the passport.
    try {
      if (collectible.collectionType == CollectionType.PASSPORT) {
        const owner = await collectibleContract?.owner();
        if (owner) {
          const thirdPartyURLs = await getThirdPartyVerifiedURL({
            networkID: networkID,
            provider: provider,
            address: owner,
          });
          collectible.thirdPartyVerifiedURL = thirdPartyURLs;
        }
      }
    } catch (e) {
      LogToLoot8Console('Offerslice-getCollectibleDetails-Collection Owner', e);
    }

    await storeData(
      APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress),
      collectible,
    );
    // LogInformation(
    //   'Offerslice/getCollectibleDetails/' + collectibleAddress,
    //   address,
    //   networkID,
    //   [
    //     { tag: 'name', value: collectible?.name },
    //     {
    //       tag: 'collectibleIds.length',
    //       value: collectible?.collectibleIds?.length?.toString(),
    //     },
    //   ],
    // );
    return collectible;
  } catch (ex) {
    LogCustomError(
      'getCollectibleDetails-' + collectibleAddress,
      ex.name,
      ex.message,
      ' ',
    );
  } finally {
    return collectible;
  }
};

export const loadPassportOfferList = createAsyncThunk(
  'offers/loadPassportOfferList',
  async (
    {
      networkID,
      provider,
      passport,
      address,
      wallet,
    }: IPassportOfferFactoryBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    await dispatch(clearFeaturedEntityOffer());
    await dispatch(clearRegularEntityOffer());
    await dispatch(clearEventEntityOffer());
    await dispatch(clearPremiumChatCollectibles());
    await dispatch(clearCollectiableEntityOffer());
    await dispatch(clearAllEntityOffer());
    await dispatch(clearMintedCoupon());
    await dispatch(clearAllEntityEvents());

    const dispatcher = Dispatcher__factory.connect(
      addresses[networkID].OrderDispatcher,
      provider,
    );

    if (passport?.linkCollectible.length > 0) {
      // get sorted addresses
      const sortByAddress = await sortCollectionLogs(networkID);

      await Promise.all(
        passport?.linkCollectible
          .filter(x => x !== ZERO_ADDRESS)
          .map(async collectibleAddress => {
            const linkedCollChainId =
              await CollectionManagerData.getCollectionChainId(
                collectibleAddress,
              );
            if (!+linkedCollChainId) return;

            const offerContext = await dispatcher.offerContext(
              collectibleAddress,
            );
            let isOwnedCollectible = 0;

            try {
              const collectibleContract = Loot8Collection__factory.connect(
                collectibleAddress,
                provider,
              );

              isOwnedCollectible =
                +(await collectibleContract.balanceOf(address)) || 0;
            } catch {}

            if (
              isOwnedCollectible === 0 &&
              +offerContext.maxPurchase &&
              +offerContext.totalPurchases +
                +offerContext.activeReservationsCount >=
                +offerContext.maxPurchase
            )
              return;

            let providerToPass = provider;
            if (!isNativeChain(+linkedCollChainId)) {
              providerToPass = getAnynetStaticProvider(+linkedCollChainId);
            }

            let collectiable = await getCollectibleDetails(
              {
                networkID: +linkedCollChainId,
                provider: providerToPass,
                collectibleAddress,
                address,
                wallet,
              },
              { entityData },
              {
                isCache: true,
                isBalanceRefresh: true,
                isMarketPlaceRefresh: false,
              },
            );
            if (
              collectiable?.maxBalance &&
              collectiable?.collectibleIds?.length >= collectiable?.maxBalance
            )
              return;

            if (
              collectiable &&
              collectiable.isActive &&
              (collectiable.isAvailable || collectiable.collectibleCount > 0)
            ) {
              switch (collectiable.collectionType) {
                case CollectionType.OFFER:
                  if (collectiable.offerType == OfferType.FEATURED) {
                    dispatch(
                      pushFeaturedEntityOffer({ collectiable, sortByAddress }),
                    );
                  } else dispatch(pushRegularEntityOffer(collectiable));
                  break;
                case CollectionType.TICKET:
                  dispatch(pushEventEntityOffer(collectiable));
                  break;
                case CollectionType.PREMIUM_ACCESS:
                  // Change price to latest buyPrice with including fees
                  let buyPrice = await getPassportFinalBuyPrice(
                    collectiable.address,
                  );
                  if (buyPrice) {
                    collectiable.price = buyPrice;
                  }
                  dispatch(pushAllPremiumCollectibles(collectiable));
                  break;
                case CollectionType.COLLECTION:
                  if (
                    collectiable.collectibleCount === 0 &&
                    collectiable.offerType == OfferType.FEATURED
                  ) {
                    dispatch(
                      pushFeaturedEntityOffer({ collectiable, sortByAddress }),
                    );
                  } else {
                    for (
                      let i = 0;
                      i < collectiable.collectibleIds.length;
                      i++
                    ) {
                      const element = {
                        ...collectiable,
                        subTitle: '#' + collectiable.collectibleIds[i],
                        tokenId: collectiable.collectibleIds[i],
                        collectibleId: collectiable.collectibleIds[i],
                      };
                      dispatch(pushCollectiableEntityOffer(element));
                    }
                  }

                  break;
                default:
                  break;
              }
            }

            // push all offers
            if (
              collectiable &&
              collectiable.isActive &&
              (collectiable.collectionType == CollectionType.OFFER ||
                collectiable.offerType === OfferType.FEATURED) &&
              collectiable.collectibleCount == 0
            ) {
              await dispatch(pushAllEntityOffer(collectiable));
            }

            // push all events
            if (
              collectiable &&
              collectiable.isActive &&
              collectiable.collectionType == CollectionType.TICKET
            ) {
              await dispatch(pushAllEntityEvents(collectiable));
            }

            // push minted coupon and order already placed
            if (
              collectiable &&
              collectiable.collectibleCount > 0 &&
              collectiable.isCoupon &&
              collectiable.isOrderPlaced
            ) {
              for (let i = 0; i < collectiable.collectibleIds.length; i++) {
                const element = {
                  ...collectiable,
                  subTitle: '#' + collectiable.collectibleIds[i],
                  tokenId: collectiable.collectibleIds[i],
                  collectibleId: collectiable.collectibleIds[i],
                };
                await dispatch(pushMintedCoupon(element));
              }
            }
          }),
      );
    }
  },
);

export const loadThirpartyCollectibleList = createAsyncThunk(
  'offers/loadThirpartyCollectibleList',
  async (
    {
      networkID,
      provider,
      passport,
      address,
      wallet,
    }: IPassportOfferFactoryBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    await dispatch(clearThirdPartyEntityOffer());

    if (
      passport.whitelistedCollections &&
      passport.whitelistedCollections.length > 0
    ) {
      await Promise.all(
        passport.whitelistedCollections
          .filter(x => x.source !== ZERO_ADDRESS)
          .map(async collectiable => {
            let contractAddress = collectiable.source;
            let chainId = collectiable.chainId;
            const thirdPartyCollectiable =
              await getThirdPartyCollectiableDetails({
                chainId,
                provider,
                address,
                collectiableAddress: contractAddress,
              });
            if (thirdPartyCollectiable?.length > 0) {
              thirdPartyCollectiable.map(async x => {
                if (x.collectibleId) {
                  await dispatch(pushThirdPartyEntityOffer(x));
                }
              });
            }
          }),
      );
    }
  },
);

export const mintOffers = createAsyncThunk(
  'offers/mintOffers',
  async (
    {
      networkID,
      provider,
      offerAddress,
      wallet,
      cashPayment,
      address,
      passportAddress,
      offerId = 0,
    }: IOfferAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    let data = GetOrderReserveMessage(
      offerAddress,
      passportAddress,
      address,
      cashPayment,
      offerId,
    );
    // if (offerType == OfferType.EVENT) data = GetOrderReserveMessage(offerAddress, address, paymentMode);
    // else data = GetOfferMintMessage(address);
    let msg: IMessageMetaData = {
      to: addresses[networkID].OrderDispatcher,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider,
    };
    let res = await dispatch(SendMetaTX(msg));
    if (res && res.payload?.eventLogs) {
      const createdReservationID = GetOfferMintedMessage(
        res.payload?.eventLogs,
      );
      LogToLoot8Console('createdReservationID', createdReservationID);
      return createdReservationID;
    }
    return 0;
  },
);

export const mintCoupons = createAsyncThunk(
  'offers/mintCoupons',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      collectibleAddress,
    }: ICollectibleAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    let data: any;

    data = GetCollectibleMintMessage(address);

    let msg: IMessageMetaData = {
      to: collectibleAddress,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider,
    };

    await dispatch(SendMetaTX(msg));
  },
);

export const loadAvailableOffersList = createAsyncThunk(
  'offers/loadAvailableOffersList',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      passport,
      userLocation,
    }: IPassportOfferFactoryAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    let allOffersDetails: ICollectibleDetail[] = [];
    let mintableOffers = [];

    allOffersDetails = state.Offers.allEntityOffer;

    // get sorted addresses
    const sortByAddress = await sortCollectionLogs(networkID);
    await Promise.all(
      allOffersDetails?.map(async offerDetail => {
        try {
          let isOpenOffer: any = true;
          let providerToPass = provider;
          if (!isNativeChain(offerDetail.chainId)) {
            providerToPass = getAnynetStaticProvider(offerDetail.chainId);
          }
          if (
            offerDetail?.address &&
            offerDetail?.area != null &&
            offerDetail.area.length == 2 &&
            offerDetail.area[1] > 0 &&
            offerDetail?.isCoupon
          ) {
            let offerAddress = offerDetail.address;
            isOpenOffer = false;
            if (userLocation != null) {
              if (isLocationAvailable(userLocation, offerDetail.area)) {
                isOpenOffer = true;
              }
            }
          }

          if (offerDetail.start && offerDetail.end) {
            if (!isActiveTimeStamp(offerDetail.start, offerDetail.end)) {
              isOpenOffer = false;
            }
          }

          // Tier mapping from offer contract to be used to check.
          if (offerDetail?.isCoupon && isOpenOffer) {
            isOpenOffer = false;
            const collectibleContract =
              Loot8TieredCouponCollection__factory.connect(
                offerDetail.address,
                providerToPass,
              );
            try {
              if (
                +(await collectibleContract.collectionCollectibleIds()) <=
                +(await collectibleContract.maxTokens())
              ) {
                const isCollectibleMinted =
                  await collectibleContract.isPatronEligibleForCoupon(address);
                if (
                  typeof isCollectibleMinted === 'object' &&
                  isCollectibleMinted[0] !== null
                ) {
                  isOpenOffer = isCollectibleMinted[0];
                } else {
                  isOpenOffer = isCollectibleMinted;
                }
              }
            } catch (e) {
              isOpenOffer = false;
            }
          }
          if (
            offerDetail?.collectibleCount == 0 &&
            isOpenOffer &&
            offerDetail?.isCoupon
          ) {
            offerDetail = await getCollectibleDetails(
              {
                networkID: offerDetail.chainId,
                provider: providerToPass,
                collectibleAddress: offerDetail.address,
                address,
                wallet,
              },
              { entityData },
              {
                isCache: true,
                isBalanceRefresh: true,
                isMarketPlaceRefresh: false,
              },
            );
            if (offerDetail?.collectibleCount == 0 && offerDetail?.isActive) {
              mintableOffers.push(offerDetail?.address);
            }
          } else if (
            isOpenOffer &&
            !offerDetail?.isCoupon &&
            offerDetail.collectibleCount == 0
          ) {
            // update featured offer and regular offer array in case offer is not coupon.
            if (offerDetail?.offerType == OfferType.FEATURED) {
              await dispatch(
                pushFeaturedEntityOffer({
                  collectiable: offerDetail,
                  sortByAddress,
                }),
              );
            } else {
              await dispatch(pushRegularEntityOffer(offerDetail));
            }
          }
        } catch (error) {
          LogCustomError(
            'loadAvailableOffersList',
            error?.name,
            error?.message,
            error,
          );
        }
      }),
    );

    if (mintableOffers.length > 0) {
      LogToLoot8Console('mintableOffers', mintableOffers);
      let toBeMintedOffers = allOffersDetails.filter(x =>
        mintableOffers.find(y => y == x?.address),
      );
      dispatch(
        mintAvailableOffers({
          networkID,
          provider,
          address,
          wallet,
          toBeMintedOffers,
          passportAddress: passport,
        }),
      );
    }
  },
);

export const mintAvailableOffers = createAsyncThunk(
  'offers/mintAvailableOffers',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      toBeMintedOffers,
      passportAddress,
    }: any,
    { dispatch, getState },
  ): Promise<any> => {
    LogToLoot8Console('calling offer mint');
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    for (let i = 0; i < toBeMintedOffers.length; i++) {
      const offerDetail: ICollectibleDetail = toBeMintedOffers[i];

      // clear cache
      await storeData(
        APP_STORAGE_GET_COLLECTIBLEDETAILS(offerDetail?.address),
        null,
      );

      try {
        // check minting queue
        let isOfferMintable = true;
        if (offersMintingQueue.has(toBeMintedOffers[i].address)) {
          // set flag if offer already in minting queue.
          isOfferMintable = false;
        } else {
          offersMintingQueue.set(toBeMintedOffers[i].address, true);
        }
        if (isOfferMintable) {
          let providerToPass = provider;
          if (!isNativeChain(offerDetail.chainId)) {
            providerToPass = getAnynetStaticProvider(offerDetail.chainId);
          }
          LogToLoot8Console('mintAvailableOffers', offerDetail);
          await dispatch(
            mintCoupons({
              networkID: offerDetail.chainId,
              provider: providerToPass,
              address: address,
              wallet: wallet,
              collectibleAddress: offerDetail?.address,
            }),
          );
          await wait(1000);

          let offer = await getCollectibleDetails(
            {
              networkID: offerDetail.chainId,
              provider: providerToPass,
              collectibleAddress: offerDetail?.address,
              address,
              wallet,
            },
            { entityData },
            {
              isCache: true,
              isBalanceRefresh: true,
              isMarketPlaceRefresh: false,
            },
          );
          if (offer && offer?.collectibleCount > 0) {
            LogToLoot8Console('offer minted', offer);

            //push notification
            dispatch(
              pushNotification({
                subject: 'Congrats! You just received a new coupon!',
                body:
                  offerDetail?.name +
                  ' has been added to the Offers. Check out!',
                //uri: friend.avatarURI,
                timeStamp: Number.parseInt(getSyncedTime().toString()),
                blockNumber: await provider.getBlockNumber(),
                id: offerDetail?.address,
                notificationType: NotificationType.CouponMint,
                dataObject: offerDetail,
              }),
            );

            storeData(
              APP_STORAGE_GET_COLLECTIBLEDETAILS(offerDetail?.address),
              null,
            );
            dispatch(
              loadPassportOfferList({
                networkID: networkID,
                provider: provider,
                passport: passportAddress,
                wallet,
                address,
              }),
            );
          }
          // update offer minting queue to set current offer to false.
          // so it will be pulled to minting queue again in case of failure.
          offersMintingQueue.set(toBeMintedOffers[i].address, false);
        }
      } catch (error) {
        LogCustomError(
          'mintAvailableOffers',
          address,
          'Failed on mintAvailableOffers',
          error,
        );
      }
    }
  },
);

export const loadAvailableEventsList = createAsyncThunk(
  'offers/loadAvailableEventsList',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      passport,
      userLocation,
    }: IPassportOfferFactoryAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    let allEventsDetails: ICollectibleDetail[] = [];

    allEventsDetails = state.Offers.allEntityEvents;

    await Promise.all(
      allEventsDetails?.map(async eventDetail => {
        try {
          let isOpenEvent: boolean = true;
          let providerToPass = provider;
          if (!isNativeChain(eventDetail.chainId)) {
            providerToPass = getAnynetStaticProvider(eventDetail.chainId);
          }

          if (
            eventDetail?.address &&
            eventDetail?.area != null &&
            eventDetail.area.length == 2 &&
            eventDetail.area[1] > 0
          ) {
            // isOpenEvent = false;
            // if (userLocation != null) {
            //   if (isLocationAvailable(userLocation, eventDetail.area)) {
            //     isOpenEvent = true;
            //   }
            // }
          }

          if (eventDetail.start && eventDetail.end) {
            if (!isActiveTimeStamp(eventDetail.start, eventDetail.end)) {
              isOpenEvent = false;
            }
          }

          if (eventDetail?.collectibleCount == 0 && isOpenEvent) {
            eventDetail = await getCollectibleDetails(
              {
                networkID: eventDetail.chainId,
                provider: providerToPass,
                collectibleAddress: eventDetail.address,
                address,
                wallet,
              },
              { entityData },
              {
                isCache: true,
                isBalanceRefresh: true,
                isMarketPlaceRefresh: false,
              },
            );
            if (eventDetail?.collectibleCount == 0 && eventDetail?.isActive) {
              await dispatch(pushEventEntityOffer(eventDetail));
            }
          }
        } catch (error) {
          LogCustomError(
            'loadAvailableEventsList',
            error?.name,
            error?.message,
            error,
          );
        }
      }),
    );
  },
);

export const getAllCollectionByCategory = async ({
  networkID,
  provider,
  collectionType,
}: {
  networkID;
  provider;
  collectionType;
}): Promise<{ source: string; chainId: number }[]> => {
  const collectionManager = CollectionManager__factory.connect(
    addresses[networkID].CollectionManager,
    provider,
  );
  let availableCollection = (
    await collectionManager.getAllCollectionsWithChainId(collectionType, true)
  )
    .map(p => {
      if (p)
        return {
          source: p.source,
          chainId: BigNumber.from(p.chainId).toNumber(),
        };
    })
    .filter(p => p);
  return availableCollection;
};

const getLatestCollectionList = (collection: any, sortByAddress: any) => {
  let latestOffer = [];
  try {
    if (collection && collection.length > 0) {
      if (sortByAddress && sortByAddress.length > 0) {
        collection.map((item: any) => {
          latestOffer.push(item);
        });
        latestOffer.sort((a, b) => {
          return (
            sortByAddress.indexOf(a.address) - sortByAddress.indexOf(b.address)
          );
        });
      }
    }
  } catch (error) {
    LogCustomError(
      'getLatestCollectionList',
      error.name,
      error.message,
      error.stack,
    );
  }
  return latestOffer;
};

const OfferSlice = createSlice({
  name: 'OfferDetails',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    clearFeaturedEntityOffer(state) {
      state.FeaturedEntityOffer = [];
    },
    pushFeaturedEntityOffer(state, action) {
      state.FeaturedEntityOffer = state.FeaturedEntityOffer.filter(x => {
        return (
          x.address?.toLowerCase() !=
          action.payload.collectiable.address?.toLowerCase()
        );
      });
      state.FeaturedEntityOffer.push(action.payload.collectiable);

      // sort offers
      let sortedOffer = getLatestCollectionList(
        state.FeaturedEntityOffer,
        action.payload.sortByAddress,
      );
      if (sortedOffer && sortedOffer.length > 0) {
        state.FeaturedEntityOffer = [...sortedOffer];
      }
    },
    clearRegularEntityOffer(state) {
      state.RegularEntityOffer = [];
    },
    pushRegularEntityOffer(state, action) {
      state.RegularEntityOffer = state.RegularEntityOffer.filter(x => {
        return (
          x.address?.toLowerCase() != action.payload.address?.toLowerCase()
        );
      });
      state.RegularEntityOffer.push(action.payload);
    },
    clearEventEntityOffer(state) {
      state.EventEntityOffer = [];
    },
    clearPremiumChatCollectibles(state) {
      state.premiumChatCollectibles = [];
    },
    pushEventEntityOffer(state, action) {
      state.EventEntityOffer = state.EventEntityOffer.filter(x => {
        return (
          x.address?.toLowerCase() != action.payload.address?.toLowerCase()
        );
      });
      state.EventEntityOffer.push(action.payload);
    },
    pushAllPremiumCollectibles(state, action) {
      if (state.premiumChatCollectibles) {
        state.premiumChatCollectibles = state.premiumChatCollectibles.filter(
          x => {
            return (
              x.address?.toLowerCase() != action.payload.address?.toLowerCase()
            );
          },
        );
        state.premiumChatCollectibles.push(action.payload);
      }
    },
    updatePremiumCollectibles(state, action) {
      if (state.premiumChatCollectibles) {
        let ind = state.premiumChatCollectibles.findIndex(
          p =>
            p.address?.toLowerCase() == action.payload.address?.toLowerCase(),
        );
        state.premiumChatCollectibles[ind].collectibleCount =
          action.payload.collectibleCount;
        state.premiumChatCollectibles[ind].collectibleIds =
          action.payload.collectibleIds;
      }
    },
    clearCollectiableEntityOffer(state) {
      state.PassportCollectiables = [];
    },
    pushCollectiableEntityOffer(state, action) {
      state.PassportCollectiables = state.PassportCollectiables.filter(x => {
        return (
          x.address?.toLowerCase() != action.payload.address?.toLowerCase()
        );
      });
      state.PassportCollectiables.push(action.payload);
      // state.PassportCollectiables.push(action.payload)
    },
    clearThirdPartyEntityOffer(state) {
      state.ThirdPartyCollectiables = [];
    },
    pushThirdPartyEntityOffer(state, action) {
      state.ThirdPartyCollectiables.push(action.payload);
    },
    clearPassportOffers(state) {
      state.FeaturedEntityOffer = [];
      state.RegularEntityOffer = [];
      state.EventEntityOffer = [];
      state.PassportCollectiables = [];
      state.ThirdPartyCollectiables = [];
      state.allEntityOffer = [];
      state.allMintedCoupon = [];
    },
    pushAllEntityOffer(state, action) {
      state.allEntityOffer = state.allEntityOffer.filter(x => {
        return (
          x.address?.toLowerCase() != action.payload.address?.toLowerCase()
        );
      });
      state.allEntityOffer.push(action.payload);
    },
    clearAllEntityOffer(state) {
      state.allEntityOffer = [];
    },
    pushMintedCoupon(state, action) {
      let existing = state.allMintedCoupon.find(
        x =>
          x.address == action.payload.address &&
          x.collectibleId == action.payload.collectibleId,
      );
      if (!existing) {
        state.allMintedCoupon.push(action.payload);
      } else {
        state.allMintedCoupon = state.allMintedCoupon.map(x => {
          if (
            x.address == action.payload.address &&
            x.collectibleId == action.payload.collectibleId
          ) {
            return action.payload;
          } else {
            return x;
          }
        });
      }
    },
    clearMintedCoupon(state) {
      state.allMintedCoupon = [];
    },
    pushAllEntityEvents(state, action) {
      state.allEntityEvents = state.allEntityEvents.filter(x => {
        return (
          x.address?.toLowerCase() != action.payload.address?.toLowerCase()
        );
      });
      state.allEntityEvents.push(action.payload);
    },
    clearAllEntityEvents(state) {
      state.allEntityEvents = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadPassportOfferList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadPassportOfferList.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(
        loadPassportOfferList.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'loadPassportOfferList',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadThirpartyCollectibleList.pending, (state, action) => {
        state.loadingThirdpartyCollecible = true;
      })
      .addCase(loadThirpartyCollectibleList.fulfilled, (state, action) => {
        state.loadingThirdpartyCollecible = false;
      })
      .addCase(
        loadThirpartyCollectibleList.rejected,
        (state: { loadingThirdpartyCollecible: boolean }, { error }: any) => {
          state.loadingThirdpartyCollecible = false;
          showToastMessage();
          LogCustomError(
            'loadingThirdpartyCollecible',
            error.name,
            error.message,
            error.stack,
          );
        },
      );
  },
});

export const OfferSliceReducer = OfferSlice.reducer;

const baseInfo = (state: RootState) => state.Offers;

export const {
  fetchAppSuccess,
  clearFeaturedEntityOffer,
  clearPassportOffers,
  pushFeaturedEntityOffer,
  clearRegularEntityOffer,
  pushRegularEntityOffer,
  clearEventEntityOffer,
  clearPremiumChatCollectibles,
  pushEventEntityOffer,
  pushAllPremiumCollectibles,
  clearCollectiableEntityOffer,
  pushCollectiableEntityOffer,
  clearThirdPartyEntityOffer,
  pushThirdPartyEntityOffer,
  pushAllEntityOffer,
  clearAllEntityOffer,
  pushMintedCoupon,
  clearMintedCoupon,
  pushAllEntityEvents,
  clearAllEntityEvents,
  updatePremiumCollectibles,
} = OfferSlice.actions;

export const getOfferState = createSelector(baseInfo, OfferSlice => OfferSlice);
