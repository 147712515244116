import React from 'react';
import { View, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { innerStyles } from './styles';
import styles from '../../../../styles';
import EmptyList from '../../../EmptyList';
import AppLoaderComponent from '../../../Loader';
import { useAppSelector } from '../../../../hooks';
import SectionHeading from '../../../SectionHeading';
import ExPassAssetsCard from '../../../ExPassAssetsCard';
import { ScreenName } from '../../../../enums/screen.enum';
import SeeAllTextPressable from '../../../SeeAllTextPressable';

const FeaturedOffers = ({
  showLimitedOffers = false,
  overrideOffers = undefined,
  isLoading = false,
  linkedTo = '',
}) => {
  const navigation: any = useNavigation();

  const featuredEntityOffer = useAppSelector(
    state => state.Offers.FeaturedEntityOffer,
  );
  const loading = overrideOffers
    ? isLoading
    : useAppSelector(state => state.Offers.loading);
  const offersCount = overrideOffers
    ? overrideOffers?.length
    : featuredEntityOffer?.length || 0;

  const handleSeeAll = () => {
    offersCount > 0 &&
      navigation.navigate('Offers', {
        updateParent: () => null,
      });
  };

  const handleFeaturedOfferDetails = selectedOffer => {
    navigation.navigate('FeaturedOfferDetail', {
      offer: {
        name: selectedOffer?.name,
        price: selectedOffer?.price,
        type: selectedOffer?.type,
        details: selectedOffer?.details,
        image: selectedOffer?.image,
        offerAddress: selectedOffer?.address,
        isCoupon: selectedOffer?.isCoupon,
        tokenId: selectedOffer?.tokenId,
        offersCount: offersCount,
        navigationFrom: ScreenName.OFFERS_TAB,
        maxPurchase: selectedOffer?.maxPurchase,
        maxBalance: selectedOffer?.maxBalance,
        imageSize: selectedOffer?.imageSize,
        area: selectedOffer?.area,
        linkedTo: linkedTo,
      },
      updateParent: () => null,
    });
  };

  return (
    <>
      <SectionHeading
        title={`Featured Offers (${offersCount || 0})`}
        renderRight={
          offersCount && !overrideOffers ? (
            <SeeAllTextPressable onPress={handleSeeAll} />
          ) : null
        }
      />
      <View style={[styles.passportBtnContainer]}>
        <View style={[styles.passportContainer]}>
          {!loading || (loading && offersCount > 0) ? (
            <View style={[styles.infoMessageContainer, { flex: 1 }]}>
              <FlatList
                numColumns={2}
                scrollEnabled={false}
                style={innerStyles.flatList}
                data={
                  overrideOffers
                    ? overrideOffers
                    : showLimitedOffers
                    ? featuredEntityOffer?.slice(0, 4)
                    : featuredEntityOffer
                }
                contentContainerStyle={innerStyles.contentContainer}
                renderItem={({ item }) => (
                  <ExPassAssetsCard
                    item={item}
                    onPress={() => handleFeaturedOfferDetails(item)}
                  />
                )}
                keyExtractor={item => item?.address}
                columnWrapperStyle={innerStyles.columnWrapper}
                ItemSeparatorComponent={() => (
                  <View style={innerStyles.separator} />
                )}
                ListEmptyComponent={
                  <View style={{ marginVertical: 30 }}>
                    <EmptyList message="No Featured Offers found." />
                  </View>
                }
              />
            </View>
          ) : (
            <View style={styles.passportCollectibleItemsLoaderContainer}>
              <AppLoaderComponent
                deemBg={false}
                extraStyle={{ borderRadius: 6 }}
                loaderText={{
                  text: 'Compiling Available Digital Offers',
                }}
              />
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export default FeaturedOffers;
