// TODO: add signed meta transactions

import { PrivateForwarder } from '../typechain/PrivateForwarder';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  addresses,
  gasEstimationEnabled,
  getNetwork,
  getStaticProvider,
  isNativeChain,
  MASTER_KEY,
  NetworkId,
  NETWORKS,
  WEBHOOK_URL,
} from '../appconstants';
import { RootState } from '../store';
import { setAll } from './helpers';

import {
  IBaseAsyncThunk,
  IMessageMetaData,
  IWalletBaseAsyncThunk,
  IWalletOnlyAsyncThunk,
} from './interfaces';
import { ethers, Wallet } from 'ethers';
import {
  HQNFTsPrivateForwarder__factory,
  PrivateForwarder__factory,
  PrivateOwnableForwarder__factory,
  User__factory,
} from '../typechain/factories';
import { wait } from '../helpers/ipfs';
import { LogCustomError, LogErrors, LogWarning } from '../helpers/AppLogger';
import { showToastMessage } from '../helpers/Gadgets';
import AwaitLock from '../helpers/AwaitLock';
import { clearUserState, resetAppUser } from './AppUserSlice';
import { clearFriendsState } from './friendsSlice';
import { clearNotifications } from './NotificationSlice';
import { clearPassportOffers } from './OfferSlice';
import { resetOrder } from './OrderSlice';
import { resetCatalog } from './CatalogSlice';
import { resetPassportMessage } from './PassportMessageSlice';
import { resetPrivateMessage } from './PrivateMessageSlice';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { deleteNativeNotificationToken } from '../helpers/Messages';
import * as Device from 'expo-device';

let lock = new AwaitLock();

const buildRequest = async (
  forwarder: PrivateForwarder,
  input: any,
  networkID,
  wallet: Wallet,
): Promise<any> => {
  const nonce = (await forwarder.getNonce(input.from)).toString();
  if (networkID === NetworkId.POLYGON_MAINNET) {
    return { value: 0, gas: 1e6, nonce, ...input };
  } else {
    let gas = 2e6;
    const submissionTime = new Date().getTime();
    try {
      //const gasPrice = await forwarder.provider.getGasPrice();
      const request = { value: 0, gas: 15e6, nonce, ...input };

      let toSign = await buildTypedData(forwarder, request);
      let signature = await wallet._signTypedData(
        toSign.domain,
        toSign.types,
        toSign.message,
      );
      const data = forwarder.interface.encodeFunctionData('execute', [
        request,
        signature,
      ]);

      gas = (
        await forwarder.provider.estimateGas({
          from: addresses[networkID].RelayerAddress,
          to: addresses[networkID].PrivateForwarder,
          data: data,
        })
      ).toNumber();
      LogToLoot8Console('timeend:', new Date().getTime());
      LogToLoot8Console(`gasEstimated: ${gas}`);
    } catch (e) {
      LogToLoot8Console('timeend-error:', new Date().getTime(), nonce, e);
      LogErrors('META-TX-GAS-ESTIMATE-ERROR', input.to, networkID, [
        { tag: 'from', value: input.from },
        { tag: 'to', value: input.to },
        { tag: 'data', value: input.data },
        { tag: 'submissionTime', value: submissionTime },
        { tag: 'processingTime', value: new Date().getTime() - submissionTime },
      ]);
    }
    return { value: 0, gas: gas, nonce, ...input };
  }
};

const buildTypedData = async (forwarder: PrivateForwarder, request: any) => {
  const chainId = (await forwarder.provider.getNetwork()).chainId;
  const typeData = getMetaTxTypeData(chainId, forwarder.address);
  return { ...typeData, message: request };
};

export interface IAvatarData {
  readonly data: IAvatarList[];
}

export interface IAvatarList {
  readonly name: string;
  readonly base64Data: string;
}

export interface IAppData {
  readonly msgLoading: boolean;
  readonly forceLogoutUser: boolean;
  readonly avatarData: IAvatarData;
  readonly nativeNotificationPermitted: boolean;
}

const initialState: IAppData = {
  msgLoading: false,
  forceLogoutUser: false,
  avatarData: null,
  nativeNotificationPermitted: false,
};

const getMetaTxTypeData = (chainId: number, verifyingContract: string): any => {
  return {
    types: {
      ForwardRequest: [
        { name: 'from', type: 'address' },
        { name: 'to', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'gas', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'data', type: 'bytes' },
      ],
    },
    domain: {
      name: 'MinimalForwarder',
      version: '0.0.1',
      chainId,
      verifyingContract,
    },
    primaryType: 'ForwardRequest',
  };
};

export const SendMetaTX = createAsyncThunk(
  'app/SignMetaTX',
  async (
    {
      networkID,
      provider,
      to,
      wallet,
      data,
      checkUserDetail = true,
    }: IMessageMetaData,
    { dispatch, getState },
  ): Promise<any> => {
    if (!Boolean(NETWORKS[networkID].chainId)) {
      return {
        status: 'INVALID_CHAIN',
        errMsg: 'The provided chain is not supported: ' + networkID,
      };
    }

    console.log('Send Meta TX Calls Async Thunk');
    //Check if User Exists, if not then logout user
    const userFactory = User__factory.connect(
      addresses[getNetwork()].User,
      getStaticProvider(),
    );
    const userAttributes = await userFactory.userAttributes(wallet.address);

    if (
      checkUserDetail &&
      (!userAttributes || (userAttributes && +userAttributes.id == 0))
    ) {
      dispatch(forceLogoutUser());
      return;
    }

    await lock.acquireAsync();
    try {
      LogToLoot8Console('transaction start');
      const response = await SendMetaTXCall({
        networkID,
        provider,
        to,
        wallet,
        data,
      });
      console.log('response from Meta TX');
      console.log(response);
      return response;
    } finally {
      await wait(1000);
      LogToLoot8Console('transaction end');
      lock.release();
    }
  },
);

export const SendMetaTXCall = async ({
  networkID,
  provider,
  to,
  wallet,
  data,
}: IMessageMetaData): Promise<any> => {
  try {
    console.log('Send Meta TX Calls');
    const from = await wallet.getAddress();
    let private_forwarder: any;
    const ADDRESS_FORWADER = addresses[networkID].PrivateForwarder;
    if (
      isNativeChain(networkID)
    ) {
      private_forwarder = PrivateForwarder__factory.connect(
        ADDRESS_FORWADER,
        provider,
      );
    } else {
      private_forwarder = PrivateOwnableForwarder__factory.connect(
        ADDRESS_FORWADER,
        provider,
      );
    }
    const request = await buildRequest(
      private_forwarder,
      { from, to, data },
      networkID,
      wallet,
    );
    const toSign = await buildTypedData(private_forwarder, request);
    const signature = await wallet._signTypedData(
      toSign.domain,
      toSign.types,
      toSign.message,
    );

    const masterWallet = new ethers.Wallet(MASTER_KEY);
    const masterSignature = await masterWallet.signMessage(signature);

    const submissionTime = new Date().getTime();
    let responseData = await fetch(WEBHOOK_URL(networkID), {
      method: 'POST',
      body: JSON.stringify({ signature, request, masterSignature }),
      headers: { 'Content-Type': 'application/json' },
    });
    let response = await responseData.json();
    LogToLoot8Console('relay-response', response);
    let waitResponseLogs: any;
    if (response.status == 'success') {
      let result = response?.result ? JSON.parse(response.result) : null;
      // check error in result object in case of any failure.
      if (result && !result.txHash) {
        const resultValue = JSON.parse(result);
        if (
          resultValue &&
          resultValue.error &&
          resultValue.error.toLowerCase() == 'prohibited'
        ) {
          LogWarning('MINTING-CHECK-FAILED', to, networkID, [
            { tag: 'autotaskId', value: response.autotaskId },
            { tag: 'autotaskRunId', value: response.autotaskRunId },
            { tag: 'requestId', value: response.requestId },
            { tag: 'result', value: result },
          ]);
        }
      }
      let tx = result?.txHash
        ? result?.txHash
        : JSON.parse(response.result).txHash;
      const transactionStart = new Date().getTime();
      const txReceipt = provider.waitForTransaction(tx);
      LogToLoot8Console('txReceipt', txReceipt);
      let waitResponse = await Promise.race([txReceipt, wait(20000)]); //timeout in millisecond
      LogToLoot8Console('waitResponse', waitResponse);
      waitResponseLogs = waitResponse?.logs;
      if (!waitResponse) {
        LogToLoot8Console('calling waitrepsonse again');
        waitResponse = await Promise.race([txReceipt, wait(60000)]); //timeout in millisecond
        LogToLoot8Console('waitResponse', waitResponse);
        if (waitResponse) {
          const transactionEnd = new Date().getTime();
          const timeDiffernt = transactionEnd - transactionStart;
          waitResponseLogs = waitResponse?.logs;
          //transactionHash,autoTaskRunId,blockNumber,networkID,submissionTime
          LogWarning('META-TX-TIME-WARNING', to, networkID, [
            { tag: 'autotaskId', value: response.autotaskId },
            { tag: 'autotaskRunId', value: response.autotaskRunId },
            { tag: 'blockNumber', value: waitResponse?.blockNumber },
            { tag: 'processingTime', value: timeDiffernt },
            { tag: 'processStartTime', value: transactionStart },
            { tag: 'submissionTime', value: submissionTime },
            { tag: 'txReceipt', value: tx },
          ]);
        } else {
          LogErrors('META-TX-TIMEOUT', to, networkID, [
            { tag: 'autotaskId', value: response.autotaskId },
            { tag: 'autotaskRunId', value: response.autotaskRunId },
            { tag: 'atLog', value: response.autotaskRunId },
            { tag: 'submissionTime', value: submissionTime },
            { tag: 'processStartTime', value: transactionStart },
            { tag: 'txReceipt', value: tx },
          ]);
          return {
            status: 'Error',
            errMsg: 'Something went wrong. Please try again later.',
          };
        }
      }
      return {
        status: 'Success',
        response: response,
        eventLogs: waitResponseLogs,
      };
    } else {
      LogErrors('META-TX-ERROR', to, networkID, [
        { tag: 'autotaskId', value: response.autotaskId },
        { tag: 'autotaskRunId', value: response.autotaskRunId },
        { tag: 'status', value: response.status },
        { tag: 'message', value: response.message },
      ]);
      return {
        status: 'Error',
        response: response,
        eventLogs: waitResponseLogs,
      };
    }
  } catch (e) {
    LogToLoot8Console(e);
    LogErrors(e, wallet.address, networkID, [
      {
        tag: 'message',
        value: e.message,
      },
    ]);
    LogCustomError('SendMetaTXCall', e.name, e.message, e.stack);
    return {
      status: 'Error',
      errMsg: 'Something went wrong. Please try again later.',
    };
  }
};

export const logoutUserActions = createAsyncThunk(
  'app/logoutUserActions',
  async (
    { networkID, provider, wallet }: IWalletOnlyAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    dispatch(clearFriendsState());
    dispatch(clearUserState());
    dispatch(clearNotifications());
    dispatch(clearPassportOffers());
    dispatch(resetOrder());
    dispatch(resetCatalog());
    dispatch(resetApp());
    dispatch(resetAppUser());
    dispatch(resetPassportMessage());
    dispatch(resetPrivateMessage(null));
    if (Device.isDevice) {
      dispatch(
        removeNotificationToken({ networkID: null, provider: null, wallet }),
      );
    }
  },
);

export const loadAvatarData = createAsyncThunk(
  'app/loadAvatarData',
  async (
    { networkID, provider }: IBaseAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    // const customData = require('../assets/ImagesBase64.json');
    const customData = await (
      await fetch(
        'https://ipfs-prod-01.loot8-services.dev/ipfs/QmTxFdmen3nPLqxWJWZgWd4HVGjGD7E1Z8W7gJXaghVp5f',
      )
    ).json();
    return { avatarData: customData };
  },
);

export const removeNotificationToken = createAsyncThunk(
  'app/removeNotificationToken',
  async ({
    networkID,
    provider,
    wallet,
  }: IWalletOnlyAsyncThunk): Promise<any> => {
    try {
      await deleteNativeNotificationToken(wallet);
    } catch (error) {
      LogCustomError(
        'removeNotificationToken',
        error.name,
        error.message,
        error.stack,
      );
    }
  },
);

const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    forceLogoutUser(state) {
      state.forceLogoutUser = true;
    },
    resetApp(state) {
      setAll(state, initialState);
    },
    setNativeNotificationPermission(state, action) {
      state.nativeNotificationPermitted = action.payload.permissionGranted;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SendMetaTX.pending, (state: { msgLoading: boolean }) => {
        state.msgLoading = true;
      })
      .addCase(SendMetaTX.fulfilled, (state, action) => {
        state.msgLoading = false;
        if (action.payload.status == 'Error') {
          showToastMessage(action.payload.errMsg);
        }
        if (action.payload.status == 'INVALID_CHAIN') {
          LogCustomError(
            'SendMetaTX',
            action.payload.status,
            action.payload.errMsg,
            ' ',
          );
        }
      })
      .addCase(
        SendMetaTX.rejected,
        (state: { msgLoading: boolean }, { error }: any) => {
          state.msgLoading = false;
          showToastMessage();
          LogCustomError('SendMetaTX', error.name, error.message, error.stack);
        },
      )
      .addCase(loadAvatarData.fulfilled, (state, action) => {
        state.avatarData = action.payload.avatarData;
      })
      .addCase(
        loadAvatarData.rejected,
        (state: { msgLoading: boolean }, { error }: any) => {
          showToastMessage();
          LogCustomError(
            'loadAvatarData',
            error.name,
            error.message,
            error.stack,
          );
        },
      );
  },
});

export const AppSliceReducer = AppSlice.reducer;

const baseInfo = (state: RootState) => state.App;

export const {
  fetchAppSuccess,
  resetApp,
  forceLogoutUser,
  setNativeNotificationPermission,
} = AppSlice.actions;

export const getAppState = createSelector(baseInfo, (app: any) => app);
